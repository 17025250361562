import React, { useEffect, useState, useRef, useCallback } from "react";
import { batch, useSelector } from "react-redux";
import moment from "moment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import {
  AddButton,
  EditButton,
  DeleteButton,
  ExportButton
} from "../../../../../Common/Buttons";
import { IconButton, Tooltip, } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import { getTimeZoneDifference } from "utils/helpers";
import CrudDialog from "../../../../../../components/Common/CrudDialog";
import DialogWithTable from "./addCombo";
import { useSnackbar } from "notistack";
import useFrontendTable from "../../utiles/useFrontendTable";
import QRCode from "react-qr-code";
import QR from "qrcode";
import { GetApp } from "@material-ui/icons";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import _ from "lodash";
import { BigLoader } from "components/Common";
import SelectionDialog from "../common/SelectionDialog";
import AsyncAutoCompleteMod from "../common/AsyncAutoCompleteMod";
import { downloadBlob } from "helpers";


function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow placement={props.place} classes={classes} {...props} />;
}

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 13,
  },
}));

const filterLabels = {
  coupon_code: "coupon_code",
  coupon_type: "coupon_type",
  batch_id: "batch_id"
};

const adFields = ["coupon_code", "coupon_type", "batch_id"];

const AdvertisingView = (props) => {
  const classes = styles();

  const [selectionDialog, setSelectionDialog] = useState(false);


  const [addModal, setAddModal] = useState(false);
  const [bulkModal, setBulkModal] = useState(false);

  const [comboAddModal, setComboAddModal] = useState(false)
  const [comboEditModal, setComboEditModal] = useState(false)

  const [addComboDiscountModal, setAddComboDiscountModal] = useState(false)
  const [editComboDiscountModal, setEditComboDiscountModal] = useState(false)
  const [editBatchCouponsModal, setEditBatchCouponsModal] = useState(false)
  const [couponList, setCouponList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("ad_name");
  const [couponType, setCouponType] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectBatchModal, setSelectBatchModal] = useState(false);
  const [couponSelected, setCouponSelected] = useState([]);
  const [batchCouponSelected, setBatchCouponSelected] = useState([]);
  const [filterable, setFilterable] = useState(adFields);
  const [devicesList, setDevicesList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [batchIds, setBatchIds] = useState([]);
  const [deviceLoader, setDeviceLoader] = useState(false);
  const [productLoader, setProductLoader] = useState(false);
  const [redeemType, setRedeemType] = useState('')
  const [selectedRedeemType, setSelectedRedeemType] = useState({ value: "all", label: "All" });
  const redeemArray = [{ value: "Coupon", label: "Coupons" },
  { value: "Combination Discount", label: "Combination Discounts" },
  { value: "all", label: "All" }]


  const qrRef = useRef(null);
  const { tableData, addEntry, editEntry, deleteEntry, resetData,
    setTableData, updateExisting, cancelEntry, setTemporaryData } = useFrontendTable();

  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [adTypeList, setAdTypeList] = useState([
    {
      value: "percentage",
      label: "Percentage",
    },
    {
      value: "absolute",
      label: "Absolute",
    },
  ]);
  const [bigLoader, setBigLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});

  const current_user = useSelector((state) => state.userReducer.current_user);
  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    {
      key: "id",
      columnName: "ID",
      type: "text",
      visible: true,
    },
    {
      key: "batch_id",
      columnName: "Batch Id",
      type: "text",
      visible: true,
      render: (value) => value ? value : "---"
    },
    {
      key: "coupon_code",
      columnName: "Coupon Code",
      label: "Coupon Code",
      type: "text",
      visible: true,
    },
    {
      key: "coupon_type",
      columnName: "Coupon Type",
      label: "Coupon Type",
      type: "text",
      visible: true,
    },
    {
      key: "percentage_value",
      columnName: "Percentage Value (%)",
      label: "Percentage Value (%)",
      type: "text",
      visible: true,
      required: true,
      render: (value) => (value ? `${value}` : "---"),
    },
    {
      key: "absolute_value",
      columnName: "Absolute Value ($)",
      label: "Absolute Value ($)",
      type: "text",
      visible: true,
      required: true,
      render: (value) => (value ? `${value}` : "---"),
    },
    {
      key: "threshold",
      columnName: "Minimum Value (Threshold) ($)",
      label: "Minimum Value (Threshold) ($)",
      type: "text",
      visible: true,
      required: true,
      render: (value) => (value ? `${value}` : "---"),
    },
    {
      key: "usage_count",
      columnName: "Used Count",
      type: "text",
      visible: true,
    },
    {
      key: "redemption_limit",
      columnName: "Usage Limit",
      type: "text",
      visible: true,
    },
    {
      key: "daily_redemption_limit",
      columnName: "Daily Usage Limit",
      type: "text",
      visible: true,
    },
    {
      key: "daily_usage_count",
      columnName: "Used Count in 24 Hrs",
      type: "text",
      visible: true,
    },
    {
      key: "start_date",
      columnName: "Start Date",
      visible: true,
      render: (value) => value ? moment(value).format("MM-DD-YYYY hh:mm:ss A") : "---"
    },
    {
      key: "expiry_date",
      columnName: "Expiry Date",
      visible: true,
      render: (value) => value ? moment(value).format("MM-DD-YYYY hh:mm:ss A") : "---"
    },
    {
      key: "active_status",
      columnName: "Status",
      visible: true,
      render: (value) => value ? value : "---"
    },
    {
      key: "created_at",
      columnName: "Created At",
      visible: true,
      render: (value) => value ? moment(value).format("MM-DD-YYYY hh:mm:ss A") : "---",
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      visible: true,
      render: (value) => value ? moment(value).format("MM-DD-YYYY hh:mm:ss A") : "---",
    },
    {
      key: "coupon_code",
      columnName: "QR Code",
      type: "text",
      form: false,
      required: false,
      visible: true,
      render: (_, value) => (
        <>
          <BootstrapTooltip
            title={"Download QR Code"}
          >
            <IconButton
              size="small"
              disabled={value.redeem_type == 'Combination Discount'}
              className="m-1"
              variant="contained"
              onClick={
                () => {
                  handleQrImageDownload(value)
                }
              }
            >

              <CloudDownloadIcon />
            </IconButton>
          </BootstrapTooltip>
        </>
      ),
    },
  ];

  const dialogFields = [
    {
      key: "coupon_code",
      columnName: "Coupon Code",
      label: "Coupon Code",
      type: "text",
      visible: true,
      required: true,
      validations: [
        {
          type: "maxLength",
          value: 40,
          message: "Length cannot be more than 40 Characters",
        },
      ],
    },
    {
      key: "coupon_type",
      label: "Percentage or Absolute",
      visible: true,
      required: true,
      type: "select",
      options: adTypeList,
      unclosable: true
    },
    {
      key: "value",
      columnName: "Value",
      label: "Value",
      type: "text",
      visible: true,
      required: true,
      disabled: !couponType,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => costValidation(fieldValue),
          message:
            couponType === "absolute"
              ? "Enter value between 1 to 100000, only two digits are allowed after decimal"
              : "Enter valid percentage in integer 1 to 100",
        },
      ],
    },
    {
      key: "threshold",
      columnName: "Minimum Value (Threshold)",
      label: "Minimum Value (Threshold)",
      type: "text",
      visible: true,
      validations: [
        {
          type: "custom",
          value: (value) => {
            if (
              (!value) || !isNaN(parseFloat(value)) &&
              Number(value) >= 1 &&
              Number(value) <= 100000 &&
              (value?.split(".")?.[1]
                ? value?.split(".")?.[1]?.length <= 2
                : true)
            ) {
              return true;
            }
          },
          message:
            "Enter value between 1 to 100000, only two digits are allowed after decimal",
        },
      ],
    },
    {
      key: "redemption_limit",
      label: "Usage Limit",
      type: "number",
      visible: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => fieldValue === "" || fieldValue === undefined || (fieldValue >= 1 && fieldValue <= 1000000),
          message: "Limit should be between 1 to 1000000",
        },
      ],
    },
    {
      key: "daily_redemption_limit",
      label: "Daily Usage Limit",
      type: "number",
      visible: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => fieldValue === "" || fieldValue === undefined || (fieldValue >= 1 && fieldValue <= 1000000),
          message: "Limit should be between 1 to 1000000",
        },
      ],
    },
    {
      key: "start_date",
      label: "Start Date",
      visible: true,
      required: false,
      type: "dateTime",
      unclosable: true,
      validations: [
        {
          type: "custom",
          value: (value) => true,
          message:
            "Enter a Date from future",
        },
      ],
    },
    {
      key: "expiry_date",
      label: "Expiry Date",
      visible: true,
      required: false,
      type: "dateTime",
      unclosable: true,
      validations: [
        {
          type: "custom",
          value: (value) => true,
          message:
            "Enter a Date from future",
        },
      ],
    },
    {
      key: "devices",
      label: "Devices",
      visible: true,
      required: true,
      type: "select",
      options: devicesList.length ? devicesList.map((device) => ({
        label: device.vm_name,
        value: device.id,
      }))
        : [{ label: "", value: null }],
      multiple: true,

    },
    {
      key: "products",
      label: "Products",
      visible: true,
      type: "multiAutoComplete",
      options: productList.length ? productList.map((product) => ({
        label: product.product_name,
        value: product.id
      })) : [{ label: "", value: null }],
      unclosable: true,
      show: true,
    },

  ];

  const combinationDiscountFields = [

    {
      key: "coupon_type",
      label: "Percentage or Absolute",
      visible: true,
      required: true,
      type: "select",
      options: adTypeList,
      unclosable: true
    },
    {
      key: "value",
      columnName: "Value",
      label: "Value",
      type: "text",
      visible: true,
      required: true,
      disabled: !couponType,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => costValidation(fieldValue),
          message:
            couponType === "absolute"
              ? "Enter value between 1 to 100000, only two digits are allowed after decimal"
              : "Enter valid percentage in integer 1 to 100",
        },
      ],
    },


    {
      key: "start_date",
      label: "Start Date",
      visible: true,
      required: false,
      type: "dateTime",
      unclosable: true,
      validations: [
        {
          type: "custom",
          value: (value) => true,
          message:
            "Enter a Date from future",
        },
      ],
    },
    {
      key: "expiry_date",
      label: "Expiry Date",
      visible: true,
      required: false,
      type: "dateTime",
      unclosable: true,
      validations: [
        {
          type: "custom",
          value: (value) => true,
          message:
            "Enter a Date from future",
        },
      ],
    },
    {
      key: "devices",
      label: "Devices *",
      visible: true,
      type: "multiAutoComplete",
      options: devicesList.length ? devicesList.map((device) => ({
        label: device.vm_name,
        value: device.id,
      }))
        : [{ label: "", value: null }],
      unclosable: true,
      show: true,
    },


  ];

  const selectBatchField = [
    {
      key: "batch_id",
      label: "Batch ID",
      visible: true,
      required: true,
      type: "select",
      options: batchIds,
      unclosable: true,
      freeSolo: false
    },
  ]

  const bulkGenerateFields = [

    {
      key: "no_of_batches",
      label: "Number of Batches",
      type: "number",
      visible: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => fieldValue >= 1 && fieldValue <= 1000,
          message: "Limit should be between 1 to 50",
        },
      ],
    },
    {
      key: "coupon_quantity",
      label: "Coupon Quantity",
      type: "number",
      visible: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => fieldValue >= 1 && fieldValue <= 1000,
          message: "Limit should be between 1 to 1000",
        },
      ],
    },
    {
      key: "coupon_type",
      label: "Percentage or Absolute",
      visible: true,
      required: true,
      type: "select",
      options: adTypeList,
      unclosable: true
    },
    {
      key: "value",
      columnName: "Value",
      label: "Value",
      type: "text",
      visible: true,
      required: true,
      disabled: !couponType,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => costValidation(fieldValue),
          message:
            couponType === "absolute"
              ? "Enter value between 1 to 100000, only two digits are allowed after decimal"
              : "Enter valid percentage in integer 1 to 100",
        },
      ],
    },
    {
      key: "threshold",
      columnName: "Minimum Value (Threshold)",
      label: "Minimum Value (Threshold)",
      type: "text",
      visible: true,
      validations: [
        {
          type: "custom",
          value: (value) => {
            if (
              (!value) || !isNaN(parseFloat(value)) &&
              Number(value) >= 1 &&
              Number(value) <= 100000 &&
              (value?.split(".")?.[1]
                ? value?.split(".")?.[1]?.length <= 2
                : true)
            ) {
              return true;
            }
          },
          message:
            "Enter value between 1 to 100000, only two digits are allowed after decimal",
        },
      ],
    },
    {
      key: "redemption_limit",
      label: "Usage Limit",
      type: "number",
      visible: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => { console.log('fieldValue', fieldValue); return fieldValue === "" || fieldValue === undefined || (fieldValue >= 1 && fieldValue <= 1000000) },
          message: "Limit should be between 1 to 1000000",
        },
      ],
    },
    {
      key: "start_date",
      label: "Start Date",
      visible: true,
      required: false,
      type: "dateTime",
      unclosable: true,
      validations: [
        {
          type: "custom",
          value: (value) => true,
          message:
            "Enter a Date from future",
        },
      ],
    },
    {
      key: "expiry_date",
      label: "Expiry Date",
      visible: true,
      required: false,
      type: "dateTime",
      unclosable: true,
      validations: [
        {
          type: "custom",
          value: (value) => true,
          message:
            "Enter a Date from future",
        },
      ],
    },
    {
      key: "devices",
      label: "Devices",
      visible: true,
      type: "select",
      required: true,
      multiple: true,
      options: devicesList.length ? devicesList.map((device) => ({
        label: device.vm_name,
        value: device.id,
      }))
        : [{ label: "", value: null }],

    },
    {
      key: "products",
      label: "Products",
      visible: true,
      type: "multiAutoComplete",
      options: productList.length ? productList.map((product) => ({
        label: product.product_name,
        value: product.id
      })) : [{ label: "", value: null }],
      unclosable: true,
      show: true,
    },
  ];

  const editBulkGenerateFields = [
    {
      key: "coupon_type",
      label: "Percentage or Absolute",
      visible: true,
      required: true,
      type: "select",
      options: adTypeList,
      unclosable: true
    },
    {
      key: "value",
      columnName: "Value",
      label: "Value",
      type: "text",
      visible: true,
      required: true,
      disabled: !couponType,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => costValidation(fieldValue),
          message:
            couponType === "absolute"
              ? "Enter value between 1 to 100000, only two digits are allowed after decimal"
              : "Enter valid percentage in integer 1 to 100",
        },
      ],
    },
    {
      key: "threshold",
      columnName: "Minimum Value (Threshold)",
      label: "Minimum Value (Threshold)",
      type: "text",
      visible: true,
      validations: [
        {
          type: "custom",
          value: (value) => {
            if (
              (!value) || !isNaN(parseFloat(value)) &&
              Number(value) >= 1 &&
              Number(value) <= 100000 &&
              (value?.split(".")?.[1]
                ? value?.split(".")?.[1]?.length <= 2
                : true)
            ) {
              return true;
            }
          },
          message:
            "Enter value between 1 to 100000, only two digits are allowed after decimal",
        },
      ],
    },
    {
      key: "redemption_limit",
      label: "Usage Limit",
      type: "number",
      visible: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => fieldValue === "" || fieldValue === undefined || (fieldValue >= 1 && fieldValue <= 1000000),
          message: "Limit should be between 1 to 1000000",
        },
      ],
    },
    {
      key: "start_date",
      label: "Start Date",
      visible: true,
      required: false,
      type: "dateTime",
      unclosable: true,
      validations: [
        {
          type: "custom",
          value: (value) => true,
          message:
            "Enter a Date from future",
        },
      ],
    },
    {
      key: "expiry_date",
      label: "Expiry Date",
      visible: true,
      required: false,
      type: "dateTime",
      unclosable: true,
      validations: [
        {
          type: "custom",
          value: (value) => true,
          message:
            "Enter a Date from future",
        },
      ],
    },
    {
      key: "devices",
      label: "Devices",
      visible: true,
      type: "select",
      required: true,
      multiple: true,
      options: devicesList.length ? devicesList.map((device) => ({
        label: device.vm_name,
        value: device.id,
      }))
        : [{ label: "", value: null }],

    },
    {
      key: "products",
      label: "Products",
      visible: true,
      type: "multiAutoComplete",
      options: productList.length ? productList.map((product) => ({
        label: product.product_name,
        value: product.id
      })) : [{ label: "", value: null }],
      unclosable: true,
      show: true,
    },
  ];

  const costValidation = (value) => {
    if (
      couponType === "absolute" &&
      !isNaN(parseFloat(value)) &&
      Number(value) >= 1 &&
      Number(value) <= 100000 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 2 : true)
    ) {
      return true;
    } else if (
      couponType === "percentage" &&
      Number.isInteger(parseFloat(value)) &&
      Number(value) >= 1 &&
      Number(value) <= 100
    ) {
      return true;
    }
  };

  const setup = () => {
    setLoader(true);
    setCouponList([]);
    setCouponSelected([]);
    setBatchCouponSelected([]);
  };

  const handleRes = (data) => {
    setCouponList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getCouponList = async (order, max, customPage = page) => {
    try {
      const params = {
        ...searchQuery,
        limit: max ? max : rowsPerPage,
        ordering: order ? order : ordering,
        page: customPage + 1,
      };
      setup();
      const { data } = await window.axiosIns("/coupons", { params });
      handleRes(data);
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };

  const getBatchIds = async () => {
    try {
      setup();
      const { data } = await window.axiosIns("/coupons/get_batch_ids");
      setBatchIds(data.data)
      console.log("batch ids", batchIds)
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };


  useEffect(() => {
    getCouponList();
    getDevices();
    getProducts();
    getBatchIds();
  }, []);

  useEffect(() => {
    handleSearch({ redeem_type: selectedRedeemType.value }, 1)
    // handleFilter(['redeem_type'])
  }, [selectedRedeemType]);


  const onTypeSelected = (value) => {
    setSelectionDialog(false);
    if (value === "ADD COUPON") {
      setRedeemType('Coupon')
      setAddModal(true)
      setCouponType('');
    } else if (value === "ADD COMBINATION DISCOUNT") {
      setRedeemType('Combination Discount')
      setAddComboDiscountModal(true)
      setCouponType('');
    }
  }

  const handleAdd = async (formData) => {
    try {
      setBigLoader(true);
      if (
        formData.coupon_type === "percentage" &&
        (formData.value < 1 || formData.value > 100)
      ) {
        enqueueSnackbar("Coupon percentage should be between  1 to 100.");
        setBigLoader(false);
        return;
      }
      if (String(formData.threshold).includes(".") && String(formData.threshold).split(".")[1]?.length > 2) {
        enqueueSnackbar("Threshold value cannot contain more than 2 decimals.");
        setBigLoader(false);
        return;
      }
      if (formData.coupon_type === "absolute" && formData.value < 1) {
        enqueueSnackbar("Coupon value should be positive.");
        setBigLoader(false);
        return;
      }
      if (formData.threshold < 0) {
        enqueueSnackbar("Threshold value should be positive.");
        setBigLoader(false);
        return;
      }
      if (formData.start_date && formData.expiry_date) {
        if (formData.start_date >= formData.expiry_date) {
          enqueueSnackbar("Please choose a start date or time before expiry date or time!");
          setBigLoader(false);
          return;
        }
      }
      if (formData.start_date) {
        if (formData.start_date < new Date()) {
          enqueueSnackbar("Please choose a start date from future!");
          setBigLoader(false);
          return;
        }
      }
      if (formData.expiry_date) {
        if (formData.expiry_date < new Date()) {
          enqueueSnackbar("Please choose a expiry date from future!");
          setBigLoader(false);
          return;
        }
      }
      const now = new Date();
      const body = {
        redeem_type: redeemType,
        coupon_type: formData.coupon_type,
        percentage_value:
          formData.coupon_type === "percentage" ? formData.value : null,
        absolute_value:
          formData.coupon_type === "absolute" ? formData.value : null,
        threshold: formData.threshold ? formData.threshold : null,
        redemption_limit: formData.redemption_limit ? formData.redemption_limit : null,
        daily_redemption_limit: formData.daily_redemption_limit ? formData.daily_redemption_limit : null,
        devices: formData.devices || [],
        is_all_devices: formData.devices?.length == devicesList?.length || false,
        expiry_date: formData.expiry_date || null,
        start_date: formData.start_date || null,
        utc_offset: now.getTimezoneOffset()
      };

      if (redeemType === 'Combination Discount') {

        if (!formData.devices || formData.devices.length === 0) {
          enqueueSnackbar("Please select any Device(s) to continue.");
          setBigLoader(false);
          return;
        }
        if (tableData?.length === 0) {
          enqueueSnackbar("Please add a combo to proceed.");
          setBigLoader(false);
          return;
        }


        let comboItems = {}
        tableData.forEach((element) => comboItems[element.product] = parseInt(element.quantity))
        body.combo_items = comboItems
      }
      else {
        body.coupon_code = formData.coupon_code
        body.products = formData.products || []
      }
      await window.axiosIns.post("/coupons", body);
      if (redeemType === 'Combination Discount') {
        enqueueSnackbar("Combination Discount Added Successfully");

      } else {
        enqueueSnackbar("Coupon Added successfully.");
      }
      setAddComboDiscountModal(false)
      setAddModal(false);
      getCouponList();
      setCouponSelected([]);
      setTableData([])
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not add Coupon. Try again."
      );
    } finally {
      // setTableData([])
      setBigLoader(false);
    }
  };

  const getDevices = async (data) => {
    setDeviceLoader(true)
    try {
      const params = {
        all: true,
        ordering: 'vm_name',
      };
      const { data } = await window.axiosIns("/device", { params });
      setDevicesList(data?.data?.results);
    } catch (err) {
    } finally {
      setDeviceLoader(false);
    }
  }
  const getProducts = async (data) => {
    setProductLoader(true)
    try {
      const params = {
        all: true,
        ordering: 'product_name',
      };
      const { data } = await window.axiosIns("/products", { params });
      setProductList(data?.results);
    } catch (err) {
    } finally {
      setProductLoader(false);
    }
  }
  const handleEdit = async (data) => {
    setBigLoader(true);
    if (
      data.coupon_type === "percentage" &&
      (data.value < 1 || data.value > 100)
    ) {
      enqueueSnackbar("Coupon percentage should be between  1 to 100.");
      setBigLoader(false);
      return;
    }
    if (String(data.threshold).includes(".") && String(data.threshold).split(".")[1]?.length > 2) {
      enqueueSnackbar("Threshold value cannot contain more than 2 decimals.");
      setBigLoader(false);
      return;
    }
    if (data.coupon_type === "absolute" && data.value < 1) {
      enqueueSnackbar("Coupon value should be positive.");
      setBigLoader(false);
      return;
    }
    if (data.start_date && data.expiry_date) {
      if ((moment(data.start_date).format("MM-DD-YYYY hh:mm:ss A")) >= (moment(data.expiry_date).format("MM-DD-YYYY hh:mm:ss A"))) {
        enqueueSnackbar("Please choose a start date or time before expiry date or time !");
        setBigLoader(false);
        return;
      }
    }
    if (data.start_date) {
      if (data.start_date < new Date()) {
        enqueueSnackbar("Please choose a start date from future!");
        setBigLoader(false);
        return;
      }
    }
    if (data.expiry_date) {
      if (data.expiry_date < new Date()) {
        enqueueSnackbar("Please choose a expiry date from future!");
        setBigLoader(false);
        return;
      }
    }
    const now = new Date();
    const coupon_id = couponSelected[0].id;
    const body = {
      redeem_type: redeemType,
      coupon_code: data.coupon_code,
      coupon_type: data.coupon_type,
      percentage_value: data.coupon_type === "percentage" ? data.value : null,
      absolute_value: data.coupon_type === "absolute" ? data.value : null,
      threshold: data.threshold ? data.threshold : null,
      daily_redemption_limit: data.daily_redemption_limit ? data.daily_redemption_limit : null,
      redemption_limit: data.redemption_limit ? data.redemption_limit : null,
      devices: data.devices || [],
      is_all_devices: data.devices?.length == devicesList?.length || false,
      products: data.products || [],
      expiry_date: data.expiry_date || null,
      start_date: data.start_date || null,
      utc_offset: now.getTimezoneOffset()
    };
    if (redeemType === 'Combination Discount') {
      if (tableData?.length === 0) {
        enqueueSnackbar("Please add a combo to proceed.");
        setBigLoader(false)
        return;
      }
      if (data.devices?.length === 0) {
        enqueueSnackbar("Please select any Device(s) to continue.");
        setBigLoader(false);
        return;
      }

      let comboItems = {}
      tableData.forEach((element) => comboItems[element.product] = parseInt(element.quantity))
      body.combo_items = comboItems
    } else {
      body.coupon_code = data.coupon_code
    }

    try {
      await window.axiosIns.patch(`/coupons/${coupon_id}`, body);
      if (redeemType === 'Combination Discount') {
        enqueueSnackbar("Combination Discount Updated Successfully");

      } else {
        enqueueSnackbar("Coupon Edited successfully.");
      }


      setEditModal(false);
      setEditComboDiscountModal(false)
      getCouponList();
      setCouponSelected([]);
      setTableData([])
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not edit coupon. Try again."
      );
    } finally {
      setBigLoader(false);
      // setTableData([])
    }
  };

  const handleBulkGenerate = async (formData) => {
    try {
      setBigLoader(true);
      if (
        formData.coupon_type === "percentage" &&
        (formData.value < 1 || formData.value > 100)
      ) {
        enqueueSnackbar("Coupon percentage should be between  1 to 100.");
        setBigLoader(false);
        return;
      }
      if (String(formData.threshold).includes(".") && String(formData.threshold).split(".")[1]?.length > 2) {
        enqueueSnackbar("Threshold value cannot contain more than 2 decimals.");
        setBigLoader(false);
        return;
      }
      if (formData.coupon_type === "absolute" && formData.value < 1) {
        enqueueSnackbar("Coupon value should be positive.");
        setBigLoader(false);
        return;
      }
      if (formData.threshold < 0) {
        enqueueSnackbar("Threshold value should be positive.");
        setBigLoader(false);
        return;
      }
      if (formData.start_date && formData.expiry_date) {
        if (formData.start_date >= formData.expiry_date) {
          enqueueSnackbar("Please choose a start date or time before expiry date or time!");
          setBigLoader(false);
          return;
        }
      }
      if (formData.start_date) {
        if (formData.start_date < new Date()) {
          enqueueSnackbar("Please choose a start date from future!");
          setBigLoader(false);
          return;
        }
      }
      if (formData.expiry_date) {
        if (formData.expiry_date < new Date()) {
          enqueueSnackbar("Please choose a expiry date from future!");
          setBigLoader(false);
          return;
        }
      }
      const body = {
        coupon_type: formData.coupon_type,
        percentage_value:
          formData.coupon_type === "percentage" ? formData.value : null,
        absolute_value:
          formData.coupon_type === "absolute" ? formData.value : null,
        threshold: formData.threshold ? formData.threshold : null,
        redemption_limit: formData.redemption_limit ? formData.redemption_limit : null,
        coupon_quantity: formData.coupon_quantity ? Number(formData.coupon_quantity) : null,
        no_of_batches: formData.no_of_batches ? Number(formData.no_of_batches) : 1,
        devices: formData.devices || [],
        is_all_devices: formData.devices?.length == devicesList?.length || false,
        products: formData.products || [],
        expiry_date: formData.expiry_date || null,
        start_date: formData.start_date || null
      };
      await window.axiosIns.post(`/coupons/bulk_generate`, body,
        {
          params: { tz: getTimeZoneDifference() },
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
          }
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Coupon-list-${moment().format("MM-DD-YYYY")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          enqueueSnackbar("Coupons generated successfully. Generated coupons exported to Excel");
          setBulkModal(false);
          getCouponList();
          setCouponSelected([]);
          getBatchIds();
        })
        .catch((err) => {
          var enc = new TextDecoder("utf-8");
          var arr = new Uint8Array(err.response.data);
          handleServerErrors(
            err,
            enqueueSnackbar,
            JSON.parse(enc.decode(arr))?.message
          )
        });
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not generate Coupon. Try again."
      );
    } finally {
      
      setBigLoader(false);
    }
  };

  const handleBatchEdit = async (formData) => {
    try {
      setBigLoader(true);
      if (
        formData.coupon_type === "percentage" &&
        (formData.value < 1 || formData.value > 100)
      ) {
        enqueueSnackbar("Coupon percentage should be between  1 to 100.");
        setBigLoader(false);
        return;
      }
      if (String(formData.threshold).includes(".") && String(formData.threshold).split(".")[1]?.length > 2) {
        enqueueSnackbar("Threshold value cannot contain more than 2 decimals.");
        setBigLoader(false);
        return;
      }
      if (formData.coupon_type === "absolute" && formData.value < 1) {
        enqueueSnackbar("Coupon value should be positive.");
        setBigLoader(false);
        return;
      }
      if (formData.threshold < 0) {
        enqueueSnackbar("Threshold value should be positive.");
        setBigLoader(false);
        return;
      }
      if (formData.start_date && formData.expiry_date) {
        if (formData.start_date >= formData.expiry_date) {
          enqueueSnackbar("Please choose a start date or time before expiry date or time!");
          setBigLoader(false);
          return;
        }
      }
      if (formData.start_date) {
        if (formData.start_date < new Date()) {
          enqueueSnackbar("Please choose a start date from future!");
          setBigLoader(false);
          return;
        }
      }
      if (formData.expiry_date) {
        if (formData.expiry_date < new Date()) {
          enqueueSnackbar("Please choose a expiry date from future!");
          setBigLoader(false);
          return;
        }
      }
      const body = {
        coupon_type: formData.coupon_type,
        percentage_value:
          formData.coupon_type === "percentage" ? formData.value : null,
        absolute_value:
          formData.coupon_type === "absolute" ? formData.value : null,
        threshold: formData.threshold ? formData.threshold : null,
        redemption_limit: formData.redemption_limit ? formData.redemption_limit : null,
        devices: formData.devices || [],
        is_all_devices: formData.devices?.length == devicesList?.length || false,
        products: formData.products || [],
        expiry_date: formData.expiry_date || null,
        start_date: formData.start_date || null
      };
      await window.axiosIns.post(`/coupons/edit_batch`, body,
        {
          params: { tz: getTimeZoneDifference(), batch_id: batchCouponSelected[0].batch_id },
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
          }
        })
        .then(() => {
          enqueueSnackbar("Coupons Updated successfully!");
          setEditBatchCouponsModal(false);
          getCouponList();
          setCouponSelected([]);
          setBatchCouponSelected([]);
        })
        .catch((err) => {
          var enc = new TextDecoder("utf-8");
          var arr = new Uint8Array(err.response.data);
          handleServerErrors(
            err,
            enqueueSnackbar,
            JSON.parse(enc.decode(arr))?.message
          )
        });
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not update Coupons. Try again."
      );
    } finally {
      setBigLoader(false);
    }
  };


  const handleBatchSelection = async (formData) => {
    if (formData?.batch_id) {
      try {
        const response = await window.axiosIns.get(`coupons/get_batch_id_configs?batch_id=${formData?.batch_id}`);

        const batchConfigs = response.data.data;
        if (batchConfigs.coupon_type === "percentage") {
          batchConfigs.value = batchConfigs.percentage_value
        } else {
          batchConfigs.value = batchConfigs.absolute_value
        }
        setBatchCouponSelected([batchConfigs]);
        setCouponType(batchConfigs.coupon_type);

        setSelectBatchModal(false);
        setEditBatchCouponsModal(true);
      } catch (error) {
        console.error('Error fetching batch configs:', error);
      }
    }
  };


  const handleFilter = (arr) => {

    setFilterable(arr);
    // if (query !== "") {
    //   let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
    //   setup();
    //   setPage(0);
    //   // window
    //   //   .axiosIns("/coupons", {
    //   //     params: { ...searchFilter, limit: rowsPerPage },
    //   //   })
    //   //   .then((data = {}) => {
    //   //     handleRes(data.data);
    //   //   })
    //   //   .catch((err) => {
    //   //     setLoader(false);
    //   //   });
    // }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get coupons. Try again."
          );
        }
      });
  };


  const handleSearch = (value, through=0) => {
    let a = query
    if (!through){
    setQuery(value);
    }
    let searchFilter = {};
    if (value !== "") {
      if (typeof value === 'object') {
        if (query){
        searchFilter = {...value, ...handleMultiFilterSearch(
          filterLabels,
          filterable,
          query
        )}
      }else{
        searchFilter= value
      }
      } else {
        searchFilter = handleMultiFilterSearch(
          filterLabels,
          filterable,
          value
        );
        if (selectedRedeemType.value != 'all') {
          searchFilter = {
            ...searchFilter,
            ...{ redeem_type: selectedRedeemType.value }
          }
        }
      }
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("/coupons", {
        params: { ...searchFilter, limit: rowsPerPage },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const exportCoupons = () => {
    let params = {
      ...searchQuery,
      variant: "false",
      state: "all",
      tz: getTimeZoneDifference(),
    };

    let coupon_ids = couponSelected.map((value) => value?.id);
    if (coupon_ids.length > 0) params["id"] = coupon_ids.join(",");

    window
      .axiosIns("/coupons/export", {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
        params: params
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Coupons-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const handleDelete = async () => {

    try {
      await Promise.all(
        couponSelected.map((val) =>
          window.axiosIns.delete(`/coupons/${val.id}`)
        )
      );
      if (redeemType === 'Combination Discount') {
        enqueueSnackbar("Combination Discount(s) deleted successfully");

      }
      else if (redeemType === 'Coupon') {
        enqueueSnackbar("Coupon(s) deleted successfully.");
      }
      else {
        enqueueSnackbar("Selected item(s) deleted successfully")
      }



      setDeleteModal(false);
      getCouponList();
      getBatchIds();
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not delete Coupon(s). Try again."
      );
    }
  };
  const handleCouponTypeChange = (field, value) => {
    if (field?.key === 'coupon_type') {
      setCouponType(value)
    }
  }
  const handleQrImageDownload = async (couponCode) => {
    try {

      const qrDataUrl = await QR.toDataURL(couponCode.coupon_code || "default-value");

      const link = document.createElement("a");
      link.href = qrDataUrl;
      link.download = `${couponCode.coupon_code || "qr-code"}.png`;
      link.click();
    } catch (error) {
      console.error("Failed to generate QR code:", error);
    }
  };

  const handleQrDownload = useCallback(() => {
    const qr = qrRef.current.innerHTML;
    const blob = new Blob([qr], { type: "image/svg+xml" });
    downloadBlob(blob, `web-store-qr.svg`);
  }, []);

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader description="Add coupons for discounts in vending machines products. Discounts can be for a specific percentage or a fixed amount of the purchase value." />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            label="Add"
            onClick={() => setSelectionDialog(true)}
            disabled={current_user.type === "SA"}
            className="mr-3"
          />
          <AddButton
            label={"Bulk Generate"}
            onClick={() => {

              // setCouponType(couponSelected[0]?.coupon_type);
              setBulkModal(true)
              setCouponType('');
              setTableData([]);
            }}
            disabled={current_user.type === "SA"}
            className="mr-3"
          />
          <EditButton
            label={"Bulk Edit"}
            onClick={() => {
              // setCouponType(couponSelected[0]?.coupon_type);
              setSelectBatchModal(true)
              setCouponType('');
              setTableData([]);
            }}
            disabled={current_user.type === "SA"}
            className="mr-3"
          />
          <EditButton
            label="Edit"
            loading={deviceLoader || productLoader}
            disabled={couponSelected.length !== 1 || current_user.type === "SA" || deviceLoader || productLoader}
            onClick={() => {
              if (redeemType === 'Combination Discount') {
                setEditComboDiscountModal(true);
                setTableData(couponSelected[0].combo);
                setTemporaryData();
              } else {
                setEditModal(true);
                setTableData([]);
              }
              setCouponType(couponSelected[0]?.coupon_type);
            }}
            className="mr-3"
          />
          <DeleteButton
            label="Delete"
            onClick={() => setDeleteModal(true)}
            disabled={couponSelected.length === 0 || current_user.type === "SU"}
            className="mr-3"
          />
          <ExportButton
            label="Export"
            onClick={exportCoupons}
            className="mr-3"
          />
          <AsyncAutoCompleteMod

            onChange={(val) => setSelectedRedeemType(val)}
            value={selectedRedeemType}
            loading={false}
            disabled={false}
            options={redeemArray}
            required
            label="Coupon Type"
          />
        </div>
        <div className="d-flex" style={{ width: "50px" }}>
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={adFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>




      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          disablePagination={false}
          data={couponList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getCouponList(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getCouponList(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={couponSelected}
          rowOnePage={10}
          onChangeSelected={(couponSelected) => {
            couponSelected = couponSelected.map((cs) => {
              const temp = cs;
              if (temp.coupon_type === "absolute") {
                temp.value = temp.absolute_value;
              } else {
                temp.value = temp.percentage_value;
              }
              return temp;
            });
            if (couponSelected.length == 1) {
              setRedeemType(couponSelected[0].redeem_type)

            } else {

              setRedeemType(null)
            }
            setCouponSelected(couponSelected);
          }}
        />

        <CrudDialog
          title="Add Coupon"
          okText="Add Coupon"
          fields={dialogFields}
          description="Please fill in the details below."
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => {
            setAddModal(false);
            setTableData([])
          }}
          onFieldChange={handleCouponTypeChange}
        />

        <CrudDialog
          title="Bulk Generate"
          okText="Generate"
          fields={bulkGenerateFields}
          description="Please fill in the details below."
          onSubmit={values => handleBulkGenerate(values)}
          open={bulkModal}
          onClose={() => setBulkModal(false)}
          onFieldChange={handleCouponTypeChange}
        />

        <CrudDialog
          title={`Edit all for Batch`}
          okText="Update"
          fields={editBulkGenerateFields}
          description="Please fill in the details below."
          onSubmit={values => handleBatchEdit(values)}
          values={batchCouponSelected[0]}
          open={editBatchCouponsModal}
          onClose={() => setEditBatchCouponsModal(false)}
          onFieldChange={handleCouponTypeChange}
        />

        <CrudDialog
          title="Edit Coupon"
          okText="Save"
          description="Please edit the details below."
          extraComponent={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                marginLeft: "0.5rem",
              }}
            >
              <div ref={qrRef}>
                <QRCode
                  size={130}
                  value={`${couponSelected[0]?.coupon_code}`}
                />
              </div>
              <span>
                <IconButton color="primary" onClick={() => handleQrImageDownload(couponSelected[0])}>
                  <GetApp />
                </IconButton>
              </span>
            </div>
          }
          fields={dialogFields}
          values={couponSelected[0]}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => {
            setEditModal(false);
            setTableData([])
          }}
          onFieldChange={handleCouponTypeChange}
        />

        <CrudDialog
          title="Add Combination Discount"
          okText="Create"
          fields={combinationDiscountFields}
          description="Please fill in the details below."
          onSubmit={(values, hasErrors) => {
            handleAdd(values);

          }}
          extraButtonText="Add Combo"
          onExtraButton={() => {
            setComboAddModal(true);
            setTemporaryData()
          }}

          open={addComboDiscountModal}
          onClose={() => { setAddComboDiscountModal(false); setTableData([]) }}
          onFieldChange={handleCouponTypeChange}
        />

        <CrudDialog
          title="Edit Combination Discount"
          okText="Update"
          description="Please edit the details below."
          fields={combinationDiscountFields}
          values={couponSelected[0]}
          onSubmit={(values) => {
            handleEdit(values);


          }}
          extraButtonText="Add Combo"
          onExtraButton={() => {
            setComboAddModal(true)
            setTemporaryData()
          }}
          open={editComboDiscountModal}
          onClose={() => { setEditComboDiscountModal(false); setTableData([]) }}
          onFieldChange={handleCouponTypeChange}
        />
        {
          selectionDialog && <SelectionDialog
            isOpen={selectionDialog}
            onCancelPressed={() => setSelectionDialog(false)}
            onTypeSelected={onTypeSelected}
          />
        }

        <DialogWithTable
          open={comboAddModal}
          onClose={() => {
            setComboAddModal(false);
            setTemporaryData()
          }}
          onAdd={addEntry}
          onEdit={editEntry}
          onDelete={deleteEntry}
          tableData={tableData}
          productList={productList}
          onDuplicate={updateExisting}
          onCancelled={cancelEntry}
        />

        <DialogWithTable
          open={comboEditModal}
          onClose={() => {
            setComboEditModal(false)
            setTemporaryData()
          }}
          onAdd={addEntry}
          onEdit={editEntry}
          onDelete={deleteEntry}
          tableData={tableData}
          productList={productList}
          onDuplicate={updateExisting}
          onCancelled={cancelEntry}
        />
        <CrudDialog
          title={`Edit Coupons for Batch ID`}
          okText="Proceed"
          fields={selectBatchField}
          description="Please Select the batch id and proceed."
          onSubmit={values => handleBatchSelection(values)}
          // values={couponSelected[0]}
          open={selectBatchModal}
          onClose={() => setSelectBatchModal(false)}
        // onFieldChange={handleCouponTypeChange}
        // handleFieldChange={handleFieldChange}
        />

        <CrudDialog

          title={redeemType === "Coupon" ? "Delete Coupon" :
            redeemType === "Combination Discount" ? "Delete Combination Discount" : "Delete Selected Item(s)"}

          description={redeemType === "Coupon" ? "Are you sure you want to delete the Coupon?" :
            redeemType === "Combination Discount" ? "Are you sure you want to delete the Combination Discount?" : "Are you sure you want to delete the Selected Item(s)?"}

          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
      </div>

      {bigLoader && <BigLoader />}
    </div>
  );
};
export default withStyles({}, { withTheme: true })(AdvertisingView);
