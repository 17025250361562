import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import {
  AddButton,
  EditButton,
  DeleteButton,
  AllocateButton,
  UnAllocateButton,
} from "../../../.././../Common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import DialogWithTable from "../common/DialogWithTable";
import _ from "lodash";
import useFrontendTable from "../../utiles/useFrontendTable";
import usePresentationCRUD from "../../utiles/usePresentationCRUD";
import { BigLoader } from "components/Common";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Loader } from "components/Common";

const filterLabels = {
  ad_id: "id",
  ad_name: "ad_name",
  operator: "operator__business_name",
};

const adFields = ["ad_id", "ad_name", "operator"];

const AdvertisingView = (props) => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [adList, setAdList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-device_count");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [adSelected, setAdSelected] = useState([]);
  const [filterable, setFilterable] = useState(adFields);
  const [operatorList, setOperatorList] = useState([]);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [UnassignModal, setUnAssignModal] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [addeviceList, setAdDeviceList] = useState([]);
  const [adTypeList, setAdTypeList] = useState([]);
  const [bigLoader, setBigLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [crudLoader, setCrudLoader] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [addFileModalAddMode, setAddFileModalAddMode] = useState(false);
  const [editFileModalAddMode, setEditFileModalAddMode] = useState(false);
  const [addFileModalEditMode, setAddFileModalEditMode] = useState(false);
  const [editFileModalEditMode, setEditFileModalEditMode] = useState(false);
  const [deleteFileModalAddMode, setDeleteFileModalAddMode] = useState(false);
  const [deleteFileModalEditMode, setDeleteFileModalEditMode] = useState(false);
  const [showVolume, setShowVolume] = useState(true);
  const [fileDimensions, setFileDimensions] = useState("");
  const [presentationSelected, setPresentationSelected] = useState([]);
  const [adName, setAdName] = useState("");
  const [adNameError, setAdNameError] = useState("");
  const [selectedAdScreenType, setSelectedAdScreenType] = useState("");
  const [adScreenTypeError, setAdScreenTypeError] = useState("");
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [operatorError, setOperatorError] = useState("");
  const [eligibleUser, setEligibleUser] = useState(false);
  const [fileTypeValidation, setFileTypeValidation] = useState(
    ".png,.jpg,.mov,.mp4,.gif,.jpeg,.JPEG,.PNG,.JPG,.MOV,.MP4,.GIF"
  );

  const {
    tableData,
    addEntry,
    editEntry,
    deleteEntry,
    resetData,
    setTableData,
  } = useFrontendTable();
  const current_user = useSelector((state) => state.userReducer.current_user);
  const {
    fetchPresentations,
    isLoading,
    addPresentation,
    editPresentation,
    deleteMultiplePresentations,
    presentationList,
    isMutating,
  } = usePresentationCRUD(adSelected?.[0]?.id);

  const { enqueueSnackbar } = useSnackbar();

  const addSteps = ["Add Advertisement", "Add Presentation"];
  const editSteps = ["Edit Advertisement", "Edit Presentation"];

  const handleNext = () => {
    validate();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    setAdName("");
    setSelectedAdScreenType("");
    setSelectedOperator("");
    setAdNameError("");
    setAdScreenTypeError("");
    setOperatorError("");
    setAddModal(false);
    setEditModal(false);
    setActiveStep(0);
    resetData();
    setAdSelected([]);
  };

  const presentationFields = [
    {
      key: "id",
      visible: false,
    },
    {
      key: "file_type",
      columnName: "File Type",
      label: "File Type",
      type: "select",
      options: [
        { value: "image", label: "Image" },
        { value: "video", label: "Video" },
      ],
      visible: true,
      required: true,
    },
    {
      key: "file",
      columnName: "File",
      visible: true,
      form: false,
      render: (_, val) => {
        return typeof val.file === "object"
          ? val?.file?.name
          : val?.presentation_name ?? "---";
      },
    },
    {
      key: "volume_level",
      columnName: "Volume Level",
      form: false,
      visible: true,
      render: (val) => val || 0,
    },
    [
      {
        key: "file",
        label: "File",
        visible: true,
        type: "file",
        accept:
          presentationSelected?.length === 0
            ? fileTypeValidation
            : presentationSelected[0]?.file_type === "image"
            ? ".png,.jpg,.gif,.jpeg,.JPEG,.PNG,.JPG,.GIF"
            : ".mov,.mp4,.MOV,.MP4",
        hint: (
          <p style={{ marginBottom: "-10px", marginTop: 4 }}>
            Supported File Types: JPG, JPEG, PNG, GIF, MP4, MOV <br />
            Max File Size: 200 MB <br />
            Recommended resolution
            <span className={classes.primaryTextColor}>
              {" "}
              {fileDimensions} pixels.
            </span>
          </p>
        ),
      },
      {
        key: "volume_level",
        label: "Volume Level",
        show: showVolume,
        type: "slider",
      },
    ],
    {
      key: "order",
      columnName: "Order",
      label: "Order",
      visible: true,
      form: false,
      type: "text",
    },
    {
      key: "duration",
      columnName: "Duration (secs)",
      label: "Duration (secs)",
      visible: true,
      form: false,
      type: "text",
    },
    /** array to show split in dialog */
    [
      {
        key: "order",
        columnName: "Order",
        label: "Order",
        visible: true,
        type: "number",
      },
      {
        key: "duration",
        columnName: "Duration",
        label: "Duration (secs)",
        visible: true,
        type: "number",
      },
    ],
  ];

  const fields = [
    {
      key: "id",
      columnName: "Ad Id",
      type: "text",
      visible: true,
    },

    {
      key: "ad_name",
      columnName: "Ad Name",
      label: "Ad Name",
      type: "text",
      visible: true,
    },
    {
      key: "operator_name",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
    },
    // {
    //   key: "ad_type",
    //   columnName: "Ad Type",
    //   label: "Ad Type",
    //   type: "text",
    //   visible: true,
    // },
    {
      key: "ad_screen",
      columnName: "Ad Screen",
      label: "Ad Screen",
      type: "text",
      visible: true,
    },
    {
      key: "devices",
      columnName: "Visible on Devices",
      type: "text",
      visible: true,
      render: (value) =>
        Array.isArray(value)
          ? value?.map((x) => x.vm_name)?.join(", ") || "---"
          : "---",
      form: false,
      disableSorting: true,
    },
    {
      key: "created_at",
      columnName: "Created At",
      visible: true,
      render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A"),
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      visible: true,
      render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A"),
    },
  ];

  const assignDialogFields = [
    {
      key: "device_ids",
      label: "Vending Machine(s)",
      visible: true,
      type: "multiAutoComplete",
      multiple: true,
      show: true,
      options: deviceList,
    },
  ];

  const UnassignDialogFields = [
    {
      key: "device_idss",
      label: "Vending Machine(s)",
      visible: true,
      type: "multiAutoComplete",
      multiple: true,
      show: true,
      loading: true,
      required: false,
      extraProps: {
        filterSelectedOptions: true,
      },
      options: addeviceList,
    },
  ];

  const fetchCompanyList = async () => {
    try {
      const { data } = await window.axiosIns.get("company", {
        params: { all: true, ordering: "business_name" },
      });
      setOperatorList((data.data || {}).results || []);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAssignVm = async (data) => {
    if (!data?.device_ids || data?.device_ids?.length <= 0) {
      enqueueSnackbar("Please select device(s) from the list.");
      return;
    }

    try {
      setCrudLoader(true);
      await window.axiosIns.post("apply_advert", {
        advert_ids: _.map(adSelected, (x) => x.id),
        device_ids: data.device_ids,
      });

      enqueueSnackbar(
        "Advertisement successfully assigned to vending machine(s)."
      );
      setCrudLoader(false);
      setAssignModal(false);
      getAdvertisementList();
      setAdSelected([]);
    } catch (err) {
      setCrudLoader(false);
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not assign advertisement. Try again."
      );
    }
  };

  const handleUnAssignVm = async (data) => {
    if (!data?.device_idss || data?.device_idss?.length <= 0) {
      enqueueSnackbar("Please select device(s) from the list.");
      return;
    }

    const adDevicesList = _.map(
      addeviceList.filter((val) => data?.device_idss.indexOf(val.value) !== -1),
      (x) => x.value
    );
    try {
      setCrudLoader(true);
      const ad_id = adSelected[0].id;

      await window.axiosIns.put(`advertisement/${ad_id}/unassign`, {
        devices: adDevicesList,
      });

      enqueueSnackbar(
        "Advertisement successfully unassigned to vending machine"
      );
      setCrudLoader(false);
      setUnAssignModal(false);
      getAdvertisementList();
      getAdDevices();
      setAdSelected([]);
    } catch (err) {
      setCrudLoader(false);
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not Unassign advertisement. Try again."
      );
    }
  };

  const setup = () => {
    setLoader(true);
    setAdList([]);
    setAdSelected([]);
  };

  const handleRes = (data) => {
    setAdList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setDataCount(data.count);
    setLoader(false);
  };

  const getAdvertisementList = async (order, max, customPage = page) => {
    try {
      const params = {
        ...searchQuery,
        limit: max ? max : rowsPerPage,
        ordering: order ? order : ordering,
        page: customPage + 1,
      };
      setup();
      const { data } = await window.axiosIns("advertisement", { params });
      handleRes(data);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const getDeviceList = async () => {
    try {
      const { data } = await window.axiosIns("device", {
        params: { all: true, ordering: "vm_name" },
      });

      setDeviceList(
        _.map(data?.data?.results, (x) => ({
          value: x.oro_id,
          label: `${x.serial_number} || ${x.vm_name || ""}`,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getAdDevices = async (adSelect) => {
    const ad_id = adSelect && adSelect[0]?.id;
    if (ad_id) {
      try {
        const { data } = await window.axiosIns(`advertisement/${ad_id}`);
        setAdDeviceList(
          _.map(data?.devices, (x) => ({
            // oro_id:x.oro_id,
            value: x.id,
            label: `${x.serial_number} || ${x.vm_name || ""}`,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  const getAdvertisementTypeList = async () => {
    try {
      const { data } = await window.axiosIns("advertisement/ad_options", {
        params: { all: true },
      });
      setAdTypeList(data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAdvertisementList();
    getAdvertisementTypeList();
    fetchCompanyList();
    getDeviceList();
    setEligibleUser(!["FO", "OP", "DU"].includes(current_user.type));
  }, []);

  useEffect(() => {
    if (selectedAdScreenType === "screen-saver") {
      setFileDimensions("1080*1920");
    } else {
      setFileDimensions("1080*608");
    }
  }, [selectedAdScreenType]);

  const handleAdd = async () => {
    if (tableData?.length === 0) {
      enqueueSnackbar("Please add AD Presentation to proceed.");
      return;
    }

    let invalid_file = [];
    let is_invalid = false;
    let media_types = {
      video: ["mov", "mp4"],
      image: ["jpg", "jpeg", "png", "gif"],
    };

    const fd = new FormData();

    _.forEach(
      tableData,
      ({ file, file_type, order, duration, volume_level }, index) => {
        let file_name = file["name"];

        if (
          !media_types[file_type].includes(
            file_name?.split(".")[1].toLowerCase()
          )
        ) {
          is_invalid = true;
          invalid_file = [file_type, file_name];
          return;
        }

        fd.append(`file_name${index}`, file_name);
        fd.append(`file${index}`, file);
        fd.append(`file_type${index}`, file_type);
        fd.append(`order${index}`, order);
        fd.append(`duration${index}`, duration);
        fd.append(
          `volume_level${index}`,
          file_type === "image" ? 0 : volume_level || 0
        );
      }
    );

    if (is_invalid) {
      enqueueSnackbar(
        `Please upload ${invalid_file[0]} instead of ${invalid_file[1]}.`
      );
      return;
    }

    fd.append("presentation_length", tableData.length);

    try {
      setCrudLoader(true);

      fd.append("ad_name", adName);
      fd.append("ad_screen", selectedAdScreenType);
      fd.append("ad_type", "always-on");
      fd.append("operator_id", selectedOperator?.value ?? null);

      await window.axiosIns.post("advertisement", fd);

      // reset ad dialog data
      handleClose();

      enqueueSnackbar("Advertisement Added successfully.");
      getAdvertisementList();
    } catch (err) {
      console.log(err);
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not add advertisement. Try again."
      );
    } finally {
      setCrudLoader(false);
      setTableData([]);
    }
  };

  const handleEdit = async () => {
    const data = {
      ad_name: adName,
      ad_screen: selectedAdScreenType,
      ad_type: "always-on",
      operator_id: selectedOperator.value,
    };
    if (presentationList?.length === 0) {
      enqueueSnackbar("Please add AD Presentation to proceed.");
      return;
    }
    const ad_id = adSelected[0].id;

    try {
      setCrudLoader(true);
      await window.axiosIns.patch(`advertisement/${ad_id}`, data);

      enqueueSnackbar("Advertisement Edited successfully.");
      setCrudLoader(false);
      handleClose();
      getAdvertisementList();
    } catch (err) {
      console.log(err);
      setCrudLoader(false);
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not edit advertisement. Try again."
      );
    }
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("advertisement", {
          params: { ...searchFilter, limit: rowsPerPage },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("advertisement", {
        params: { ...searchFilter, limit: rowsPerPage },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleDelete = async () => {
    try {
      setCrudLoader(true);
      await Promise.all(
        adSelected.map((val) =>
          window.axiosIns.delete(`advertisement/${val.id}`)
        )
      );

      enqueueSnackbar("Advertisement(s) deleted successfully.");
      setCrudLoader(false);
      setDeleteModal(false);
      getAdvertisementList();
    } catch (err) {
      setCrudLoader(false);
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not delete Advertisement(s). Try again."
      );
    }
  };

  const checkAssignButton = () => {
    const checkUnique = _.uniqBy(adSelected, "ad_screen");

    if (checkUnique.length !== adSelected.length) {
      return true;
    }
  };

  const isFileTypeValid = (filename) => {
    const validExtensions = fileTypeValidation.split(",");
    const extension = filename.split(".").pop();
    return validExtensions.includes("." + extension);
  };

  const validate = () => {
    const adNameError = validateAdName(adName);
    const adScreenTypeError = validateAdScreenType(selectedAdScreenType);
    const operatorError = validateOperator(selectedOperator);

    setAdNameError(adNameError);
    setAdScreenTypeError(adScreenTypeError);
    setOperatorError(operatorError);

    if (!adNameError && !adScreenTypeError && !operatorError) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const validateAdName = (value) => {
    if (value.trim() === "") {
      return "Required";
    }
    if (value.length > 70) {
      return "Max length 70";
    }
    return "";
  };

  const validateAdScreenType = (value) => {
    if (value.trim() === "") {
      return "Required";
    }
    return "";
  };

  const validateOperator = (value) => {
    if (eligibleUser) {
      if (value === null || value === "" || value === undefined) {
        return "Required";
      }
    }
    return "";
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Advertising"
        description="
All Advertisements that are created are displayed here. You can also edit, delete, assign and unassign the ads from here."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            disabled={current_user.type === "SU"}
          />
          <AllocateButton
            disabled={
              current_user.type === "SU" ||
              adSelected.length <= 0 ||
              checkAssignButton()
            }
            className="mr-3"
            label="Assign"
            onClick={() => setAssignModal(true)}
          />
          <UnAllocateButton
            disabled={current_user.type === "SU" || adSelected.length !== 1}
            className="mr-3"
            label="Unassign"
            onClick={() => {
              setUnAssignModal(true);
            }}
          />
          <EditButton
            disabled={adSelected.length !== 1 || current_user.type === "SU"}
            className="mr-3"
            label="Edit"
            onClick={() => {
              setSelectedOperator(
                operatorList
                  .map((op) => ({
                    label: `${op.business_name} || (${op.customer_type})`,
                    value: op.id,
                  }))
                  .find((op) => op.value === adSelected[0]?.operator_id)
              );
              setAdName(adSelected[0]?.ad_name);
              setSelectedAdScreenType(adSelected[0]?.ad_screen);
              setEditModal(true);
              fetchPresentations();
            }}
          />
          <DeleteButton
            disabled={adSelected.length === 0 || current_user.type === "SU"}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
        </div>
        <div className="d-flex">
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={adFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={adList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getAdvertisementList(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getAdvertisementList(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          // onChangePage={(page) => console.log(page)}
          selectedRecords={adSelected}
          rowOnePage={10}
          onChangeSelected={(adSelected) => {
            setAdSelected(adSelected);
            getAdDevices(adSelected);
          }}
        />
        <Dialog
          open={addModal}
          onClose={handleClose}
          maxWidth="md"
          fullWidth={true}
          style={{ display: "block" }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Loader visible={crudLoader} />
          <DialogContent>
            <Grid container>
              <Grid item xs={6}>
                <DialogTitle style={{ padding: "0" }}>
                  {activeStep === 1 ? "Add Presentation" : "Add Advertisement"}
                </DialogTitle>
                <DialogContentText>
                  {activeStep === 1
                    ? "Please fill the form fields below"
                    : "Please fill in the details below."}
                </DialogContentText>
              </Grid>
              <Grid item xs={6}>
                <Stepper activeStep={activeStep}>
                  {addSteps.map((label, index) => {
                    return (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>
            </Grid>
            {activeStep === 0 && (
              <Grid
                container
                spacing={2}
                style={{
                  padding: "16px 0",
                }}
              >
                <Grid item xs={6}>
                  <TextField
                    type="text"
                    variant="outlined"
                    required={true}
                    label="Ad Name"
                    value={adName}
                    error={!!adNameError}
                    helperText={adNameError || ""}
                    onChange={(e) => setAdName(e.target.value)}
                    onBlur={(e) => {
                      setAdName(e.target.value?.trim());
                      setAdNameError(validateAdName(e.target.value));
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    required={true}
                    error={!!adScreenTypeError} // Pass error state here
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Ad Screen
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedAdScreenType || ""}
                      onChange={(e) => {
                        setSelectedAdScreenType(e.target.value);
                      }}
                      onBlur={(e) =>
                        setAdScreenTypeError(
                          validateAdScreenType(e.target.value)
                        )
                      }
                      label="Ad Screen"
                    >
                      {adTypeList?.ad_screen &&
                        Object.entries(adTypeList.ad_screen).map(
                          ([key, value], index) => (
                            <MenuItem key={index} value={value}>
                              {key}
                            </MenuItem>
                          )
                        )}
                    </Select>
                    {adScreenTypeError && (
                      <FormHelperText>{adScreenTypeError}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {eligibleUser && (
                  <Grid item xs={6}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={operatorList.map((value) => ({
                        label: `${value.business_name} || (${value.customer_type})`,
                        value: value.id,
                      }))}
                      style={{ display: "block" }}
                      value={selectedOperator}
                      onChange={(_, val) => setSelectedOperator(val)}
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      getOptionLabel={(option) => option?.label || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ width: "100%" }}
                          label="Operator"
                          onBlur={(e) =>
                            setOperatorError(validateOperator(e.target.value))
                          }
                          required={true}
                          error={!!operatorError}
                          helperText={operatorError || ""}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid
                container
                spacing={2}
                style={{
                  padding: "16px 0",
                }}
              >
                <AddButton
                  onClick={() => setAddFileModalAddMode(true)}
                  className="mr-3 mb-3"
                  label="Add File"
                />
                <EditButton
                  disabled={presentationSelected.length !== 1}
                  onClick={() => setEditFileModalAddMode(true)}
                  className="mr-3 mb-3"
                  label="Edit File"
                />
                <DeleteButton
                  disabled={presentationSelected.length <= 0}
                  onClick={() => setDeleteFileModalAddMode(true)}
                  className="mr-3 mb-3"
                  label="Delete File"
                />
                <TableGenerator
                  fields={presentationFields}
                  data={tableData}
                  backendPagination={false}
                  dataCount={tableData.length}
                  selectedRecords={presentationSelected}
                  loader={isLoading}
                  rowOnePage={10}
                  onChangeSelected={(adSelected) => {
                    if (adSelected?.[0]?.["file_type"] === "image") {
                      setShowVolume(false);
                    } else {
                      setShowVolume(true);
                    }
                    setPresentationSelected(adSelected);
                  }}
                />
              </Grid>
            )}
          </DialogContent>
          <DialogActions style={{ padding: "0 24px 24px" }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={activeStep === 1 ? handleBack : handleClose}
            >
              {activeStep === 1 ? "Back" : "Close"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              autoFocus
              onClick={activeStep === 1 ? handleAdd : handleNext}
            >
              {activeStep === 1 ? "Add" : "Next"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={editModal}
          onClose={handleClose}
          maxWidth="md"
          fullWidth={true}
          style={{ display: "block" }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Loader visible={crudLoader} />
          <DialogContent>
            <Grid container>
              <Grid item xs={6}>
                <DialogTitle style={{ padding: "0" }}>
                  {activeStep === 1
                    ? "Edit Presentation"
                    : "Edit Advertisement"}
                </DialogTitle>
                <DialogContentText>
                  {activeStep === 1
                    ? "Please fill the form fields below"
                    : "Please edit the details below."}
                </DialogContentText>
              </Grid>
              <Grid item xs={6}>
                <Stepper activeStep={activeStep}>
                  {editSteps.map((label, index) => {
                    return (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>
            </Grid>
            {activeStep === 0 && (
              <Grid
                container
                spacing={2}
                style={{
                  padding: "16px 0",
                }}
              >
                <Grid item xs={6}>
                  <TextField
                    type="text"
                    variant="outlined"
                    required={true}
                    label="Ad Name"
                    value={adName}
                    error={!!adNameError}
                    helperText={adNameError || ""}
                    onChange={(e) => setAdName(e.target.value)}
                    onBlur={(e) => {
                      setAdName(e.target.value?.trim());
                      setAdNameError(validateAdName(e.target.value));
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    required={true}
                    error={!!adScreenTypeError} // Pass error state here
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Ad Screen
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedAdScreenType || ""}
                      onChange={(e) => {
                        setSelectedAdScreenType(e.target.value);
                      }}
                      onBlur={(e) =>
                        setAdScreenTypeError(
                          validateAdScreenType(e.target.value)
                        )
                      }
                      label="Ad Screen"
                    >
                      {adTypeList?.ad_screen &&
                        Object.entries(adTypeList.ad_screen).map(
                          ([key, value], index) => (
                            <MenuItem key={index} value={value}>
                              {key}
                            </MenuItem>
                          )
                        )}
                    </Select>
                    {adScreenTypeError && (
                      <FormHelperText>{adScreenTypeError}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {eligibleUser && (
                  <Grid item xs={6}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={operatorList.map((value) => ({
                        label: `${value.business_name} || (${value.customer_type})`,
                        value: value.id,
                      }))}
                      style={{ display: "block" }}
                      value={selectedOperator}
                      onChange={(_, val) => setSelectedOperator(val)}
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      getOptionLabel={(option) => option?.label || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ width: "100%" }}
                          label="Operator"
                          onBlur={(e) =>
                            setOperatorError(validateOperator(e.target.value))
                          }
                          required={true}
                          error={!!operatorError}
                          helperText={operatorError || ""}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid
                container
                spacing={2}
                style={{
                  padding: "16px 0",
                }}
              >
                <AddButton
                  onClick={() => setAddFileModalEditMode(true)}
                  className="mr-3 mb-3"
                  label="Add File"
                />
                <EditButton
                  disabled={presentationSelected.length !== 1}
                  onClick={() => setEditFileModalEditMode(true)}
                  className="mr-3 mb-3"
                  label="Edit File"
                />
                <DeleteButton
                  disabled={presentationSelected.length <= 0}
                  onClick={() => setDeleteFileModalEditMode(true)}
                  className="mr-3 mb-3"
                  label="Delete File"
                />
                <TableGenerator
                  fields={presentationFields}
                  data={presentationList}
                  backendPagination={false}
                  dataCount={presentationList.length}
                  selectedRecords={presentationSelected}
                  loader={isLoading}
                  rowOnePage={10}
                  onChangeSelected={(adSelected) => {
                    if (adSelected?.[0]?.["file_type"] === "image") {
                      setShowVolume(false);
                    } else {
                      setShowVolume(true);
                    }
                    setPresentationSelected(adSelected);
                  }}
                />
              </Grid>
            )}
          </DialogContent>
          <DialogActions style={{ padding: "0 24px 24px" }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={activeStep === 1 ? handleBack : handleClose}
            >
              {activeStep === 1 ? "Back" : "Close"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              autoFocus
              onClick={activeStep === 1 ? handleEdit : handleNext}
            >
              {activeStep === 1 ? "Save" : "Next"}
            </Button>
          </DialogActions>
        </Dialog>

        <CrudDialog
          title="Add File"
          okText="Add File"
          fields={presentationFields}
          onFieldChange={(field, value) => {
            if (field.key === "file_type") {
              if (value === "video") {
                setFileTypeValidation(".mov,.mp4,.MOV,.MP4");
                setShowVolume(true);
              }

              if (value === "image") {
                setFileTypeValidation(
                  ".png,.jpg,.gif,.jpeg,.JPEG,.PNG,.JPG,.GIF"
                );
                setShowVolume(false);
              }
            }
          }}
          description="Please fill in the details below."
          onSubmit={(values, hasErrors) => {
            if (values.file === undefined) {
              enqueueSnackbar("Please select a file");
              return;
            } else if (!isFileTypeValid(values.file.name)) {
              enqueueSnackbar("Please select a valid file");
              return;
            } else if (isNaN(parseInt(values.order))) {
              enqueueSnackbar("Invalid Order. Please enter valid Order");
              return;
            } else if (
              Number(values.order) <= 0 ||
              Number(values.order) > 100
            ) {
              enqueueSnackbar("Order must be between 1-100");
              return;
            } else if (
              _.find(tableData, (x) => Number(x.order) === Number(values.order))
            ) {
              enqueueSnackbar("Order must be unique per presentation");
              return;
            } else if (isNaN(parseInt(values.duration))) {
              enqueueSnackbar("Invalid Duration. Please enter valid Duration");
              return;
            } else if (values.duration <= 0) {
              enqueueSnackbar("Duration must be greater than 0");
              return;
            } else if (values.duration > 600) {
              enqueueSnackbar("Duration must be less than 600");
            } else {
              const fsize = values?.file?.size;

              if (fsize > 209715200) {
                enqueueSnackbar("File size must not exceed 200 MB");
                return;
              }
            }

            if (values?.file_type === "image") values.volume_level = 0;

            addEntry({ ...values, id: new Date().getTime() });
            setAddFileModalAddMode(false);
          }}
          open={addFileModalAddMode}
          onClose={() => setAddFileModalAddMode(false)}
        />

        <CrudDialog
          title="Edit File"
          okText="Save"
          fields={presentationFields}
          description="Please fill in the details below."
          onFieldChange={(field, value) => {
            if (field.key === "file_type") {
              if (value === "video") {
                setFileTypeValidation(".mov,.mp4,.MOV,.MP4");
                setShowVolume(true);
              }

              if (value === "image") {
                setFileTypeValidation(
                  ".png,.jpg,.gif,.jpeg,.JPEG,.PNG,.JPG,.GIF"
                );
                setShowVolume(false);
              }
            }
          }}
          onSubmit={(values, hasErrors) => {
            if (values.file === undefined) {
              enqueueSnackbar("Please select a file");
              return;
            } else if (isNaN(parseInt(values.order))) {
              enqueueSnackbar("Invalid Order. Please enter valid Order");
              return;
            } else if (
              Number(values.order) <= 0 ||
              Number(values.order) > 100
            ) {
              enqueueSnackbar("Order must be between 1-100");
              return;
            } else if (
              _.find(
                tableData,
                (x) =>
                  Number(x.order) === Number(values.order) && values.id !== x.id
              )
            ) {
              enqueueSnackbar("Order must be unique per presentation");
              return;
            } else if (isNaN(parseInt(values.duration))) {
              enqueueSnackbar("Invalid Duration. Please enter valid Duration");
              return;
            } else if (values.duration <= 0) {
              enqueueSnackbar("Duration must be greater than 0");
              return;
            } else if (values.duration > 600) {
              enqueueSnackbar("Duration must be less than 600");
            } else {
              const fsize = values?.file?.size;

              if (fsize > 209715200) {
                enqueueSnackbar("File size must not exceed 200 MB");
                return;
              }
            }

            editEntry(values, presentationSelected[0].id);
            setPresentationSelected([]);
            setEditFileModalAddMode(false);
          }}
          values={presentationSelected[0]}
          open={editFileModalAddMode}
          onClose={() => setEditFileModalAddMode(false)}
        />

        <CrudDialog
          title="Delete File"
          description="Are you sure you want to delete the file?"
          okText="Delete"
          onSubmit={() => {
            deleteEntry(_.map(presentationSelected, (val) => val.id));
            setPresentationSelected([]);
            setDeleteFileModalAddMode(false);
          }}
          open={deleteFileModalAddMode}
          onClose={() => setDeleteFileModalAddMode(false)}
        />
        <CrudDialog
          title="Add File"
          okText="Add File"
          fields={presentationFields}
          onFieldChange={(field, value) => {
            if (field.key === "file_type") {
              if (value === "video") {
                setFileTypeValidation(".mov,.mp4,.MOV,.MP4");
                setShowVolume(true);
              }

              if (value === "image") {
                setFileTypeValidation(
                  ".png,.jpg,.gif,.jpeg,.JPEG,.PNG,.JPG,.GIF"
                );
                setShowVolume(false);
              }
            }
          }}
          description="Please fill in the details below."
          onSubmit={(values, hasErrors) => {
            if (values.file === undefined) {
              enqueueSnackbar("Please select a file");
              return;
            } else if (!isFileTypeValid(values.file.name)) {
              enqueueSnackbar("Please select a valid file");
              return;
            } else if (isNaN(parseInt(values.order))) {
              enqueueSnackbar("Invalid Order. Please enter valid Order");
              return;
            } else if (
              Number(values.order) <= 0 ||
              Number(values.order) > 100
            ) {
              enqueueSnackbar("Order must be between 1-100");
              return;
            } else if (
              _.find(presentationList, (x) => Number(x.order) === Number(values.order))
            ) {
              enqueueSnackbar("Order must be unique per presentation");
              return;
            } else if (isNaN(parseInt(values.duration))) {
              enqueueSnackbar("Invalid Duration. Please enter valid Duration");
              return;
            } else if (values.duration <= 0) {
              enqueueSnackbar("Duration must be greater than 0");
              return;
            } else if (values.duration > 600) {
              enqueueSnackbar("Duration must be less than 600");
            } else {
              const fsize = values?.file?.size;

              if (fsize > 209715200) {
                enqueueSnackbar("File size must not exceed 200 MB");
                return;
              }
            }

            if (values?.file_type === "image") values.volume_level = 0;

            addPresentation({ ...values, id: new Date().getTime() });
            setAddFileModalEditMode(false);
          }}
          open={addFileModalEditMode}
          onClose={() => setAddFileModalEditMode(false)}
        />

        <CrudDialog
          title="Edit File"
          okText="Save"
          fields={presentationFields}
          description="Please fill in the details below."
          onFieldChange={(field, value) => {
            if (field.key === "file_type") {
              if (value === "video") {
                setFileTypeValidation(".mov,.mp4,.MOV,.MP4");
                setShowVolume(true);
              }

              if (value === "image") {
                setFileTypeValidation(
                  ".png,.jpg,.gif,.jpeg,.JPEG,.PNG,.JPG,.GIF"
                );
                setShowVolume(false);
              }
            }
          }}
          onSubmit={(values, hasErrors) => {
            if (values.file === undefined) {
              enqueueSnackbar("Please select a file");
              return;
            } else if (isNaN(parseInt(values.order))) {
              enqueueSnackbar("Invalid Order. Please enter valid Order");
              return;
            } else if (
              Number(values.order) <= 0 ||
              Number(values.order) > 100
            ) {
              enqueueSnackbar("Order must be between 1-100");
              return;
            } else if (
              _.find(
                presentationList,
                (x) =>
                  Number(x.order) === Number(values.order) && values.id !== x.id
              )
            ) {
              enqueueSnackbar("Order must be unique per presentation");
              return;
            } else if (isNaN(parseInt(values.duration))) {
              enqueueSnackbar("Invalid Duration. Please enter valid Duration");
              return;
            } else if (values.duration <= 0) {
              enqueueSnackbar("Duration must be greater than 0");
              return;
            } else if (values.duration > 600) {
              enqueueSnackbar("Duration must be less than 600");
            } else {
              const fsize = values?.file?.size;

              if (fsize > 209715200) {
                enqueueSnackbar("File size must not exceed 200 MB");
                return;
              }
            }

            editPresentation(values, presentationSelected[0].id);
            setPresentationSelected([]);
            setEditFileModalEditMode(false);
          }}
          values={presentationSelected[0]}
          open={editFileModalEditMode}
          onClose={() => setEditFileModalEditMode(false)}
        />

        <CrudDialog
          title="Delete File"
          description="Are you sure you want to delete the file?"
          okText="Delete"
          onSubmit={() => {
            deleteMultiplePresentations(
              _.map(presentationSelected, (val) => val.id)
            );
            setPresentationSelected([]);
            setDeleteFileModalEditMode(false);
          }}
          open={deleteFileModalEditMode}
          onClose={() => setDeleteFileModalEditMode(false)}
        />

        <CrudDialog
          title="Assign Vending machine(s)"
          okText="Assign"
          description="Please fill the details below."
          fields={assignDialogFields}
          crudLoader={crudLoader}
          onSubmit={(values) => {
            handleAssignVm(values);
          }}
          open={assignModal}
          onClose={() => setAssignModal(false)}
        />
        <CrudDialog
          title="Unassign Vending machine(s)"
          okText="Update"
          description="Please fill the details below."
          fields={UnassignDialogFields}
          crudLoader={crudLoader}
          values={{
            device_idss: addeviceList && addeviceList.map((x) => x.value),
          }}
          onSubmit={(values) => {
            handleUnAssignVm(values);
          }}
          open={UnassignModal}
          onClose={() => setUnAssignModal(false)}
        />

        <CrudDialog
          title="Delete Advertisement"
          description="Are you sure you want to delete the Advertisement?"
          okText="Delete"
          crudLoader={crudLoader}
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
      </div>

      {(isMutating || bigLoader) && <BigLoader />}
    </div>
  );
};
export default withStyles({}, { withTheme: true })(AdvertisingView);
