import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import {
  AddButton,
  EditButton,
  DeleteButton,
  DeployButton,
  ExportButton,
  ImportButton,
} from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import _flatten from "lodash/flatten";
import CrudDialog from "../common/CrudDialog";
import moment from "moment";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckboxField from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import { Loader } from "components/Common";
import {
  convertUtcToLocal,
  currencyFormatter,
  dateFormatter,
  getTimeZoneDifference,
  taxFormatter,
} from "utils/helpers";
import {
  FileUploadInfoButton,
  FileUploadButton,
  VideoUploadButton,
} from "../common/Buttons";
import ImagesModal from "./images_info";
import InfoModal from "components/Common/AlertDialog/info_dialogue";
import useFrontendTable from "./useFrontendTable";
import DialogWithTable from "./addLanguage";

const filterLabels = {
  product_name: "product_name",
  product_sku: "product_sku",
  product_category: "categories__category_name",
  product_brand: "brand__brand",
  operator: "operator__business_name",
  package: "package_type",
  status: "status",
};

const sortLabels = {
  brand_name: "brand__brand",
  "-brand_name": "-brand__brand",
  product_name: "product_name",
  "-product_name": "-product_name",
};

const deviceTypeFields = [
  "product_name",
  "product_sku",
  "product_category",
  "product_brand",
  "operator",
  "package",
  "status",
];

const NO_SALE_DAYS = [
  { label: "Sunday", value: "Sunday" },
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
];

const Product = () => {
  const classes = styles();
  const [openProductModal, setOpenProductModal] = useState(false);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [categoryEditModal, setCategoryEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [forcedeleteModal, setForceDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [customerList, setCustomersList] = useState([]);
  const [subCategory, setSubCategory] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false);
  const [showTimePeriod, setShowTimePeriod] = useState(false);
  const [fileTypeValidation, setFileTypeValidation] = useState("");
  const [productName, setProductName] = useState("");
  const [productNameError, setProductNameError] = useState("");
  const [packageName, setPackageName] = useState("");
  const [productSKU, setProductSKU] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categorySearchQuery, setCategorySearchQuery] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    label: "Enable",
    value: "Enable",
  });
  const [selectedAgeRestriction, setSelectedAgeRestriction] = useState({
    label: "Not Required",
    value: "0",
  });
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [salePrice, setSalePrice] = useState("");
  const [salePriceError, setSalePriceError] = useState("");
  const [productCost, setProductCost] = useState("");
  const [productCostError, setProductCostError] = useState("");
  const [sellingStartTime, setSellingStartTime] = useState("00:00");
  const [sellingEndTime, setSellingEndTime] = useState("00:00");
  const [purchaseLimit, setPurchaseLimit] = useState("");
  const [purchaseLimitError, setPurchaseLimitError] = useState("");
  const [shelfLife, setShelfLife] = useState("");
  const [weight, setWeight] = useState("");
  const [weightError, setWeightError] = useState("");
  const [productTax, setProductTax] = useState("0.0000");
  const [productTaxError, setProductTaxError] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [subtitleError, setSubtitleError] = useState("");
  const [description, setDescription] = useState("");
  const [languageAddModal, setLanguageAddModal] = useState(false);

  const [numberOfFiles, setNumberOfFiles] = useState(0);
  const [openAddlImagesModal, setOpenAddlImagesModal] = useState(false);
  const [imagesData, setImagesData] = useState(null);
  const [allImages, setAllImages] = useState([]);

  const [barcodeFileName, setBarcodeFileName] = useState("");
  const [barcodeFile, setBarcodeFile] = useState(null);
  const [productPictureName, setProductPictureName] = useState("");
  const [productPicture, setProductPicture] = useState(null);
  const [moreInfoFileName, setMoreInfoFileName] = useState("");
  const [moreInfoFile, setMoreInfoFile] = useState(null);
  const [ingredientsFileName, setIngredientsFileName] = useState("");
  const [ingredientsFile, setIngredientsFile] = useState(null);
  const [instructionsFileName, setInstructionsFileName] = useState("");
  const [instructionsFile, setInstructionsFile] = useState(null);

  const [recommendProduct, setRecommendProduct] = useState(false);
  const [checkExtension, setCheckExtension] = useState({
    product_picture: true,
    barcode: true,
    more_info: true,
    ingredients: true,
    instructions_video: true,
  });

  const image_extensions_array = [
    "jpg",
    "jpeg",
    "png",
    "JPG",
    "JPEG",
    "PNG",
    "gif",
    "GIF",
  ];
  const video_extensions_array = ["mp4", "MP4", "mov", "MOV"];

  const AGE_RESTRICTION_VALUES = [
    { label: "Not Required", value: "0" },
    { label: "18", value: "18" },
    { label: "21", value: "21" },
  ];

  const filteredCategories = categoryList.filter((category) =>
    category.category_name
      .toLowerCase()
      .includes(categorySearchQuery.toLowerCase())
  );

  const {
    tableData,
    addEntry,
    editEntry,
    deleteEntry,
    resetData,
    setTableData,
    updateExisting,
    cancelEntry,
    setTemporaryData,
    updateExistingProducts,
  } = useFrontendTable();

  const current_user = useSelector((state) => state.userReducer.current_user);
  const enable_product_dropdown = useSelector(
    (state) => state.productsSettingsReducer.enable_product_dropdown
  );
  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    { key: "id", columnName: "ID", label: "ID", type: "text", visible: true },
    {
      key: "product_sku",
      columnName: "Product SKU",
      label: "Product SKU",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value || "---",
    },
    {
      key: "product_name",
      columnName: "Product Name",
      label: "Product Name",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },

    {
      key: "operator_name",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
    },
    {
      key: "category_list",
      columnName: "Product Categories",
      label: "Product Categories",
      type: "text",
      visible: true,
      disableSorting: true,
      render: (value) =>
        value ? value.map((cat) => cat.category_name).join(", ") : "---",
    },
    {
      key: "sub_category_name",
      columnName: "Product Subcategory",
      label: "Product Subcategory",
      type: "text",
      visible: (subCategory && subCategory["enable_subcategory"]) || false,
      render: (value) => value ?? "---",
    },
    {
      key: "brand_name",
      columnName: "Product Brand",
      label: "Product Brand",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "product_picture",
      columnName: "Picture",
      label: "Product Picture",
      type: "fileWithPeriod",
      form: true,
      visible: true,
      disableSorting: true,
      render: (_, values) =>
        values.product_picture && (
          <div
            style={{
              height: 64,
              width: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.product_picture})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "goods_code",
      columnName: "Goods Code",
      label: "Goods Code",
      type: "text",
      visible: false,
      render: (value) => value ?? "---",
    },
    {
      key: "barcode_of_product",
      columnName:
        subCategory && subCategory["change_barcode_to_desc_img"]
          ? "Description Image"
          : "BarCode",
      width: 100,
      label:
        subCategory && subCategory["change_barcode_to_desc_img"]
          ? "Description Image"
          : "BarCode",
      type: "file",
      form: true,
      visible: (subCategory && subCategory["enable_barcode"]) ?? true,
      disableSorting: true,
      render: (_, values) =>
        values.barcode_of_product && (
          <div
            style={{
              height: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.barcode_of_product})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "more_info_img",
      columnName: "More Info",
      width: 100,
      label: "More Info",
      type: "file",
      form: true,
      visible: (subCategory && subCategory["enable_info_img"]) || false,
      disableSorting: true,
      render: (_, values) =>
        values.more_info_img && (
          <div
            style={{
              height: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.more_info_img})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "ingredients_img",
      columnName: "Ingredients",
      width: 100,
      label: "Ingredients",
      type: "file",
      form: true,
      visible: (subCategory && subCategory["enable_ingredient_img"]) || false,
      disableSorting: true,
      render: (_, values) =>
        values.ingredients_img && (
          <div
            style={{
              height: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.ingredients_img})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "package_type",
      columnName: "Package",
      label: "Package",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "price",
      columnName: "Sale Price ($)",
      label: "Price ($)",
      type: "text",
      visible: true,
      render: (value) => currencyFormatter(value) ?? "---",
    },
    {
      key: "cost",
      columnName: "Cost ($)",
      label: "Cost ($)",
      type: "text",
      visible: true,
      render: (value) => currencyFormatter(value) ?? "---",
    },
    {
      key: "product_tax",
      columnName: "Tax (%)",
      label: "Tax (%)",
      type: "text",
      visible:
        (subCategory && subCategory["enable_taxes_per_product"]) || false,
      render: (value) => taxFormatter(value) ?? "0.0000",
    },
    {
      key: "weight",
      columnName: "Weight in grams",
      label: "Weight (in grams)",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
    },
    {
      key: "shelf_life",
      columnName: "Shelf life (days)",
      label: "Shelf life (days)",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "purchase_limit",
      columnName: "Purchase Limit",
      label: "Purchase Limit",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    // {
    //   key: "heating_time",
    //   columnName: "Heating time",
    //   label: "Heating time",
    //   type: "text",
    //   visible: true,
    //   render: (value) => value ?? "---",
    // },
    {
      key: "age_restriction",
      columnName: "Age Restriction",
      label: "Age Restriction",
      type: "text",
      visible: true,
      render: (value) => (value === "0" ? "Not Required" : value ?? "---"),
    },
    {
      key: "status",
      columnName: "Status",
      label: "Status",
      type: "text",
      visible: true,
      required: true,
      render: (value) => (value && value !== "undefined" ? value : "---"),
    },
    {
      key: "product_description",
      columnName: "Subtitle",
      label: "Subtitle",
      type: "text",
      visible: true,
      render: (value) => (
        <Tooltip title={value} aria-label="description">
          <span>{value || "---"}</span>
        </Tooltip>
      ),
    },
    {
      key: "long_description",
      columnName: "Description",
      label: "Description",
      type: "textarea",
      visible: true,
      render: (value) => (
        <Tooltip title={value} aria-label="description">
          <span>{value ? `${value.substring(0, 30)}...` : "---"}</span>
        </Tooltip>
      ),
    },
    {
      key: "created_at",
      columnName: "Creation Time",
      label: "Creation Time",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
    },
    {
      key: "updated_at",
      columnName: "Update time stamp",
      label: "Update time stamp",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
    },
  ];

  const taxValidation = (value) => {
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100 &&
      (("" + value)?.split(".")?.[1]
        ? value?.split(".")?.[1]?.length <= 4
        : true)
    ) {
      return true;
    }
  };

  const validateTax = (value) => {
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100 &&
      (("" + value)?.split(".")?.[1]
        ? value?.split(".")?.[1]?.length <= 4
        : true)
    ) {
      return "";
    }
    return "Enter tax between 0 to 100, only four digits are allowed after decimal";
  };

  const validateProductName = (value) => {
    if (value.trim() === "") {
      return "Required";
    }
    if (value.length > 70) {
      return "Max length 70";
    }
    return "";
  };

  const validateSubtitle = (value) => {
    if (value.length > 100) {
      return "Max length 100";
    }
    return "";
  };

  const validateWeight = (value) => {
    if (!value) return "";
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 3 : true)
    ) {
      return "";
    }
    return "Enter a valid number, with upto 3 decimal places";
  };

  const validatePurchaseLimit = (value) => {
    if (value.trim() === "" || value === undefined || value === null) {
      return "";
    }
    if (Number(value) < 1 || Number(value) > 5) {
      return "Quantity should be between 1 to 5";
    }
    return "";
  };

  const validateSalePrice = (value) => {
    if (value.trim() === "") {
      return "Required";
    }
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100000 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 2 : true)
    ) {
      return "";
    }
    return "Enter Sale price between 0 to 100000, only two digits are allowed after decimal";
  };

  const handleFileChange = async (
    e,
    setFileName,
    setFile,
    key,
    isImage = true
  ) => {
    let extension = e[0].name.split(".")[1];
    if (
      isImage
        ? image_extensions_array.includes(extension)
        : video_extensions_array.includes(extension)
    ) {
      setCheckExtension((prev) => ({ ...prev, [key]: true }));
      setFileName(e[0].name);
      setFile(e[0]);
    } else {
      setFileName("");
      setFile(null);
      setCheckExtension((prev) => ({ ...prev, key: false }));
    }
  };

  const getImages = (field, e) => {
    let images = [];
    for (let i = 0; i < e.length; i++) {
      images.push(e[i]);
    }

    // typeof x === "string" ? x.split("/").pop().split("?")[0] :
    setImagesData(images?.map((x) => [getImageSrc(x), x.name]));
    setNumberOfFiles(images.length);
    setAllImages(images);
  };

  const removeImages = (val) => {
    let images = [];
    for (let i = 0; i < allImages.length; i++) {
      if (typeof allImages[i] === "string") {
        if (allImages[i] !== val[0]) {
          images.push(allImages[i]);
        }
      } else {
        if (allImages[i].name !== val[1]) {
          images.push(allImages[i]);
        }
      }
    }
    setNumberOfFiles(images.length);
    setImagesData(
      images?.map((x) => [
        getImageSrc(x),
        typeof x === "string" ? x.split("/").pop().split("?")[0] : x.name,
      ])
    );
    setAllImages(images);
  };

  const validateProductCost = (value) => {
    if (value.trim() === "") {
      return "Required";
    }
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100000 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 2 : true)
    ) {
      return "";
    }
    return "Enter Cost price between 0 to 100000, only two digits are allowed after decimal";
  };

  const getImageSrc = (image) => {
    return typeof image === "string" ? image : URL.createObjectURL(image);
  };

  const getComponents = (position = 1) => {
    if (subCategory && subCategory["enable_subcategory"] && position === 1) {
      return (
        <Grid item xs={6}>
          <Autocomplete
            id="combo-box-demo"
            size="small"
            options={
              categoryList.length
                ? categoryList
                    .filter((data) => data.is_subcategory)
                    .map((x) => ({
                      label: x.category_name,
                      value: x.id,
                    }))
                : [{ label: "", value: null }]
            }
            style={{ display: "block" }}
            value={selectedSubCategory}
            onChange={(_, val) => setSelectedSubCategory(val)}
            getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={(option) => option?.label || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: "100%" }}
                label="Product Subcategory"
                variant="outlined"
              />
            )}
          />
        </Grid>
      );
    } else if (
      (!(subCategory && subCategory["enable_subcategory"]) && position === 1) ||
      (subCategory && subCategory["enable_subcategory"] && position === 2)
    ) {
      return (
        <Grid item xs={6}>
          <Autocomplete
            id="combo-box-demo"
            size="small"
            required
            options={AGE_RESTRICTION_VALUES}
            style={{ display: "block" }}
            disabled={
              JSON.parse(localStorage.getItem("scanner_state")) ? false : true
            }
            value={selectedAgeRestriction}
            onChange={(_, val) => setSelectedAgeRestriction(val)}
            getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={(option) => option?.label || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                required={true}
                style={{ width: "100%" }}
                label="Age Restriction"
                variant="outlined"
              />
            )}
          />
        </Grid>
      );
    } else if (position === 3) {
      return !(
        current_user.type === "FO" ||
        current_user.type === "OP" ||
        current_user.type === "DU"
      ) ? (
        <Grid item xs={6}>
          <Autocomplete
            id="combo-box-demo"
            size="small"
            options={customerList.map((value) => ({
              label: `${value.business_name} || (${value.customer_type})`,
              value: value.id,
            }))}
            style={{ display: "block" }}
            value={selectedOperator}
            onChange={(_, val) => setSelectedOperator(val)}
            getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={(option) => option?.label || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: "100%" }}
                label="Operator"
                variant="outlined"
              />
            )}
          />
        </Grid>
      ) : null;
    } else {
      return null;
    }
  };

  const handleTimeChange = (newTime, setter) => {
    if (newTime) {
      setter(newTime);
    } else {
      setter("00:00");
    }
  };

  const editFields1 = [
    [
      {
        key: "categories",
        label: "Product Categories",
        columnName: "Product Categories",
        type: "multiAutoComplete",
        show: true,
        // required: true,
        freeSolo: false,
        inputProps: {
          tabIndex: "2",
        },
        options: categoryList.length
          ? categoryList
              .filter((data) => !data.is_subcategory)
              .map((x) => ({
                label: x.category_name,
                value: x.id,
              }))
          : [{ label: "", value: null }],
        visible: true,
      },
    ],
    [
      {
        key: "brand",
        label: "Product Brand",
        columnName: "Product Brand",
        type: "autocomplete",
        // required: true,
        show: true,
        inputProps: {
          tabIndex: "3",
        },
        freeSolo: false,
        options: brandList.length
          ? [...brandList.map((x) => ({ label: x.brand, value: x.id }))]
          : [{ label: "", value: null }],
        visible: true,
      },
    ],
    [
      {
        key: "age_restriction",
        columnName: "Age Restriction",
        label: "Age Restriction",
        visible: true,
        freeSolo: false,
        required: true,
        inputProps: {
          tabIndex: "13",
        },
        show: true,
        disabled: JSON.parse(localStorage.getItem("scanner_state"))
          ? false
          : true,
        type: "autocomplete",
        options: [
          { label: "Not Required", value: "0" },
          { label: "18", value: 18 },
          { label: "21", value: 21 },
        ],
        defaultValue: { label: "Not Required", value: "0" },
      },
      {
        key: "product_tax",
        columnName: "Tax (%)",
        label: "Tax (%)",
        type: "text",
        value: "0.0000",
        inputProps: {
          tabIndex: "9",
        },
        visible:
          (subCategory && subCategory["enable_taxes_per_product"]) || false,
        required: false,
        validations: [
          {
            type: "custom",
            value: (fieldValue) =>
              subCategory && subCategory["enable_taxes_per_product"]
                ? taxValidation(fieldValue)
                : true,
            message:
              "Enter tax between 0 to 100, only four digits are allowed after decimal",
          },
        ],
      },
    ],

    [
      {
        key: "selling_start_time",
        label: "Start Time for Selling",
        type: "time",
        visible:
          showTimePeriod &&
          subCategory &&
          subCategory["enable_product_selling_time"],
      },
      {
        key: "selling_end_time",
        label: "End Time for Selling",
        type: "time",
        visible:
          showTimePeriod &&
          subCategory &&
          subCategory["enable_product_selling_time"],
      },
    ],
    [
      {
        key: "no_sale_days",
        label: "No Sale Days",
        visible:
          showTimePeriod &&
          subCategory &&
          subCategory["enable_product_selling_time"],
        type: "multiAutoComplete",
        inputProps: {
          tabIndex: "16",
        },
        options: [
          { label: "Sunday", value: "Sunday" },
          { label: "Monday", value: "Monday" },
          { label: "Tuesday", value: "Tuesday" },
          { label: "Wednesday", value: "Wednesday" },
          { label: "Thursday", value: "Thursday" },
          { label: "Friday", value: "Friday" },
          { label: "Saturday", value: "Saturday" },
        ],
        show: true,
        unclosable: true,
      },
    ],
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results.filter((x) => x.variant_type === "default"));
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getDeviceType = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      state: "all",
      page: customPage + 1,
      variant: false,
    };

    // if (current_user.type === "FO" || current_user.type === "OP") {
    //   params.operator = current_user.id;
    // }

    setup();
    const { data } = await window.axiosIns("/products", { params });
    handleRes(data);
  };

  const fetchCompanyList = async () => {
    try {
      const { data } = await window.axiosIns.get("company", {
        params: { all: true, ordering: "business_name" },
      });
      setCustomersList((data.data || {}).results || []);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getCategory = () => {
    window
      .axiosIns("/categories", {
        params: { all: true, ordering: "category_name" },
      })
      .then(({ data = {} }) => {
        setCategoryList(data.results);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get categories. Try again."
          );
        }
      });
  };

  const getProductSettings = async () => {
    window
      .axiosIns("/products_settings")
      .then(({ data = {} }) => {
        setSubCategory(data.results?.[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBrand = async () => {
    window
      .axiosIns("/brand", { params: { all: true, ordering: "brand" } })
      .then(({ data = {} }) => {
        setBrandList(data.results);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get brands. Try again."
          );
        }
      });
  };

  const getLanguages = async () => {
    window
      .axiosIns("/languages", { params: { all: true } })
      .then(({ data = {} }) => {
        setLanguageList(data.results);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get brands. Try again."
          );
        }
      });
  };

  useEffect(() => {
    getProductSettings();
    getDeviceType();
    getCategory();
    fetchCompanyList();
    getBrand();
    getLanguages();
    setFileTypeValidation(".mov,.mp4,.MOV,.MP4");
  }, []);

  useEffect(() => {
    if (selectedAgeRestriction.value.toString() === "0") {
      setShowTimePeriod(false);
    } else {
      setShowTimePeriod(true);
    }
  }, [selectedAgeRestriction]);

  const isFileTypeValid = (fileName) => {
    if (fileName) {
      const validExtensions = fileTypeValidation.split(",");
      const extension = fileName.split(".").pop();
      return validExtensions.includes("." + extension);
    }
    return true;
  };

  const handleAdd = () => {
    if (productName === "") {
      enqueueSnackbar("'Product Name' cannot be empty");
      return;
    }
    if (salePrice === "") {
      enqueueSnackbar("'Sale Price' cannot be empty");
      return;
    }
    if (productCost === "") {
      enqueueSnackbar("'Product Cost' cannot be empty");
      return;
    }
    if (
      productNameError ||
      salePriceError ||
      productCostError ||
      purchaseLimitError ||
      weightError ||
      productTaxError ||
      subtitleError
    ) {
      enqueueSnackbar("Please rectify the above errors");
      return;
    }
    if (
      subCategory &&
      subCategory["enable_product_selling_time"] &&
      selectedAgeRestriction.value !== "0"
    ) {
      if (!sellingStartTime && sellingEndTime) {
        enqueueSnackbar("'Start Time for Selling' cannot be empty");
        return;
      }
      if (sellingStartTime && !sellingEndTime) {
        enqueueSnackbar("'End Time for Selling' cannot be empty");
        return;
      }
    }
    if (instructionsFile && !isFileTypeValid(instructionsFile?.name)) {
      enqueueSnackbar("Please select a valid file for instruction video.");
      return;
    }

    const fd = new FormData();
    // images && fd.append("images", images);

    if (allImages && allImages.length) {
      for (let i = 0; i < allImages.length; i++) {
        fd.append(`img${i + 1}`, allImages[i]);
      }
    }
    productPicture && fd.append("product_picture", productPicture);
    barcodeFile && fd.append("barcode_of_product", barcodeFile);
    moreInfoFile && fd.append("more_info_img", moreInfoFile);
    ingredientsFile && fd.append("ingredients_img", ingredientsFile);
    // category_image && fd.append("category_image", category_image);
    instructionsFile && fd.append("instructions_video", instructionsFile);
    fd.append("long_description", description ?? "");
    fd.append("product_description", subtitle ?? "");
    // fd.append("category_description", category_description ?? "");
    fd.append(
      "categories",
      selectedCategories.length > 0
        ? JSON.stringify(selectedCategories.map((category) => category.id))
        : "[]"
    );
    fd.append("sub_category", selectedSubCategory?.value ?? "");
    fd.append("product_name", productName);
    fd.append("package_type", packageName ?? "");
    fd.append("price", parseFloat(salePrice).toFixed(2));
    fd.append("cost", parseFloat(productCost).toFixed(2));
    fd.append("shelf_life", shelfLife ?? "");
    fd.append("purchase_limit", purchaseLimit ?? "");
    // fd.append("heating_time", heating_time ?? "");
    fd.append("brand", selectedBrand?.value ?? "");
    // fd.append("product_size", product_size);
    // fd.append("product_intro", product_intro);
    fd.append("status", selectedStatus ? selectedStatus?.value : "Enable");
    fd.append("product_sku", productSKU ?? "");
    // fd.append("product_type", product_type);
    fd.append("weight", weight ?? "");
    fd.append("operator", selectedOperator?.value ?? "");
    fd.append("age_restriction", selectedAgeRestriction?.value ?? "0");
    fd.append("is_recommended", recommendProduct ?? false);
    fd.append("product_tax", productTax ?? "0.0000");
    fd.append(
      "no_sale_days",
      (selectedDays &&
        selectedDays.length &&
        selectedDays.map((days) => days.value)) ||
        "[]"
    );
    selectedAgeRestriction?.value !== "0" &&
      sellingStartTime &&
      fd.append("selling_start_time", sellingStartTime ?? "");
    selectedAgeRestriction?.value !== "0" &&
      sellingEndTime &&
      fd.append("selling_end_time", sellingEndTime ?? "");
    let languageVariants = {};
    if (tableData) {
      tableData.forEach(
        (element) =>
          (languageVariants[element.language] = {
            product_name: element.product_name,
            product_description: element.product_description,
            long_description: element.long_description,
          })
      );
    }
    fd.append("language_variants", JSON.stringify(languageVariants) ?? {});
    setIsMutating(true);
    setCrudLoader(true);
    window.axiosIns
      .post(`/products`, fd)
      .then(() => {
        setCrudLoader(false);
        enqueueSnackbar("Product Added successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
        handleClose();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add product. Try again."
          );
        }
        setCrudLoader(false);
        setTableData([]);
        setIsMutating(false);
      })
      .finally(() => {
        setDeviceList([]);
        setModulesSelected([]);
        getDeviceType();
      });
  };

  const handleEdit = async () => {
    if (productName === "") {
      enqueueSnackbar("'Product Name' cannot be empty");
      return;
    }
    if (salePrice === "") {
      enqueueSnackbar("'Sale Price' cannot be empty");
      return;
    }
    if (productCost === "") {
      enqueueSnackbar("'Product Cost' cannot be empty");
      return;
    }
    if (
      productNameError ||
      salePriceError ||
      productCostError ||
      purchaseLimitError ||
      weightError ||
      productTaxError ||
      subtitleError
    ) {
      enqueueSnackbar("Please rectify the above errors");
      return;
    }
    if (
      subCategory &&
      subCategory["enable_product_selling_time"] &&
      selectedAgeRestriction.value !== "0"
    ) {
      if (!sellingStartTime && sellingEndTime) {
        enqueueSnackbar("'Start Time for Selling' cannot be empty");
        return;
      }
      if (sellingStartTime && !sellingEndTime) {
        enqueueSnackbar("'End Time for Selling' cannot be empty");
        return;
      }
    }

    if (instructionsFile && !isFileTypeValid(instructionsFile?.name)) {
      enqueueSnackbar("Please select a valid file for instruction video.");
      return;
    }

    const fd = new FormData();
    if (allImages && allImages.length) {
      for (let i = 0; i < allImages.length; i++) {
        fd.append(`img${i + 1}`, allImages[i]);
      }
    }
    instructionsFile &&
      instructionsFile.type &&
      fd.append("instructions_video", instructionsFile);
    productPicture &&
      productPicture.type &&
      fd.append("product_picture", productPicture);
    barcodeFile &&
      barcodeFile.type &&
      fd.append("barcode_of_product", barcodeFile);
    ingredientsFile &&
      ingredientsFile.type &&
      fd.append("ingredients_img", ingredientsFile);
    moreInfoFile &&
      moreInfoFile.type &&
      fd.append("more_info_img", moreInfoFile);
    fd.append("product_description", subtitle ?? "");
    fd.append("long_description", description ?? "");
    fd.append(
      "categories",
      selectedCategories.length > 0
        ? JSON.stringify(selectedCategories.map((category) => category.id))
        : "[]"
    );
    fd.append("sub_category", selectedSubCategory?.value ?? "");
    fd.append("product_name", productName);
    fd.append("package_type", packageName ?? "");
    fd.append("price", parseFloat(salePrice).toFixed(2));
    fd.append("cost", parseFloat(productCost).toFixed(2));
    fd.append("shelf_life", shelfLife ?? "");
    fd.append("purchase_limit", purchaseLimit ?? "");
    // fd.append("replication", replication);
    // fd.append("heating_time", heating_time ?? "");
    fd.append("brand", selectedBrand?.value ?? "");
    // fd.append("product_size", product_size);
    // fd.append("product_intro", product_intro);
    fd.append("status", selectedStatus ? selectedStatus?.value : "Enable");
    fd.append("product_sku", productSKU ?? "");
    // fd.append("product_type", product_type ?? "");
    fd.append("weight", weight ?? "");
    fd.append("operator", selectedOperator?.value ?? "");
    fd.append("age_restriction", selectedAgeRestriction?.value ?? "0");
    fd.append("is_recommended", recommendProduct ?? false);
    fd.append("product_tax", productTax ?? "0.0000");
    fd.append(
      "no_sale_days",
      (selectedDays &&
        selectedDays.length &&
        selectedDays.map((days) => days.value)) ||
        "[]"
    );
    selectedAgeRestriction?.value !== "0" &&
      sellingStartTime &&
      fd.append("selling_start_time", sellingStartTime ?? "");
    selectedAgeRestriction?.value !== "0" &&
      sellingEndTime &&
      fd.append("selling_end_time", sellingEndTime ?? "");
    let languageVariants = {};
    if (tableData) {
      tableData.forEach(
        (element) =>
          (languageVariants[element.language] = {
            product_name: element.product_name,
            product_description: element.product_description,
            long_description: element.long_description,
          })
      );
    }
    fd.append("language_variants", JSON.stringify(languageVariants) ?? {});
    const moduleId = modulesSelected[0].id;
    setIsMutating(true);
    setCrudLoader(true);
    window.axiosIns
      .patch(`/products/${moduleId}`, fd)
      .then(() => {
        setCrudLoader(false);
        enqueueSnackbar("Product edited successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
        handleClose();
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit product. Try again."
          );
        }
        setCrudLoader(false);
        setTableData([]);
        setIsMutating(false);
      })
      .then(() => {
        setDeviceList([]);
        setModulesSelected([]);
        getDeviceType();
      });
  };

  const handleCategoryEdit = ({
    categories,
    brand,
    age_restriction,
    product_tax,
    no_sale_days,
    selling_start_time,
    selling_end_time,
  }) => {
    if (
      subCategory &&
      subCategory["enable_product_selling_time"] &&
      age_restriction !== "0"
    ) {
      if (!selling_start_time && selling_end_time) {
        enqueueSnackbar("'Start Time for Selling' cannot be empty");
        return;
      }
      if (selling_start_time && !selling_end_time) {
        enqueueSnackbar("'End Time for Selling' cannot be empty");
        return;
      }
    }
    const fd = new FormData();

    fd.append("categories", categories ? JSON.stringify(categories) : "[]");
    fd.append("brand", brand ?? "");
    fd.append("age_restriction", age_restriction ?? "0");
    fd.append("product_tax", product_tax ?? "0.0000");
    fd.append(
      "no_sale_days",
      (no_sale_days && no_sale_days.length && no_sale_days) || "[]"
    );
    age_restriction !== "0" &&
      selling_start_time &&
      fd.append("selling_start_time", selling_start_time ?? "");
    age_restriction !== "0" &&
      selling_end_time &&
      fd.append("selling_end_time", selling_end_time ?? "");

    const moduleId = modulesSelected[0].id;

    setIsMutating(true);
    setCrudLoader(true);
    for (let i = 0; i < modulesSelected.length; i++) {
      window.axiosIns
        .patch(`/products/${modulesSelected[i].id}`, fd)
        .then(() => {
          setCategoryEditModal(false);
          setCrudLoader(false);
          if (i === modulesSelected.length - 1)
            enqueueSnackbar("Products were updated successfully.");
          setTimeout(() => {
            setIsMutating(false);
          }, 200);
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (((err.response || {}).data || {}).detail) {
            enqueueSnackbar(((err.response || {}).data || {}).detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not edit product. Please try again."
            );
          }
          setCategoryEditModal(false);
          setCrudLoader(false);
          setIsMutating(false);
        })
        .then(() => {
          setDeviceList([]);
          setModulesSelected([]);
          getDeviceType();
        });
    }
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/products", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            variant_type: "default",
            ordering,
            variant: false,
          },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const productExport = () => {
    let params = {
      ...searchQuery,
      variant: "false",
      state: "all",
      tz: getTimeZoneDifference(),
    };

    let product_ids = modulesSelected.map((value) => value?.id);
    if (product_ids.length > 0) params["id"] = product_ids.join(",");

    window
      .axiosIns("/products/export", {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
        params: params,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Product-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const syncPlanogram = () => {
    setLoader(true);
    const fd = new FormData();
    window.axiosIns
      .post(`/planogram/sync_planograms`, fd)
      .then(() => {
        enqueueSnackbar("Products were synced successfully.");
        setLoader(false);
      })
      .catch((err) => {
        if (err.response?.data?.detail) {
          enqueueSnackbar(err.response.data.detail);
          setLoader(false);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not Sync product(s). Try again."
          );
          setLoader(false);
        }
      });
  };

  const changePage = async (url) => {
    setup();
    const { data } = await window.axiosIns(url);
    handleRes(data);
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("/products", {
        params: {
          ...searchFilter,
          limit: rowsPerPage,
          state: "all",
          variant: false,
          ordering,
        },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleDelete = () => {
    modulesSelected.forEach((val, index) => {
      window.axiosIns
        .delete(`/products/${val.id}`)
        .then(() => {
          setDeleteModal(false);
          if (modulesSelected.length === index + 1) {
            if (
              deviceTypeList.length - modulesSelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getDeviceType();
            }
            enqueueSnackbar("Product deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else if (err.response.data.message) {
            // setForceDeleteModal(true)
            // enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete product. Try again."
            );
          }
        });
    });
  };

  const calculateImageMaxHeight = () => {
    let height = 448;
    if (
      showTimePeriod &&
      subCategory &&
      subCategory["enable_product_selling_time"]
    ) {
      height += 56;
    }
    if (
      subCategory["enable_subcategory"] ||
      subCategory["enable_taxes_per_product"]
    ) {
      height += 56;
    }
    return height + "px";
  };

  const handleForceDelete = () => {
    modulesSelected.forEach((val, index) => {
      const params = {
        force: "true",
      };
      window.axiosIns
        .delete(`/products/${val.id}`, { params })
        .then(() => {
          setDeleteModal(false);
          setForceDeleteModal(false);
          if (modulesSelected.length === index + 1) {
            if (
              deviceTypeList.length - modulesSelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getDeviceType();
            }
            enqueueSnackbar("Product deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete product. Try again."
            );
          }
        });
    });
  };

  const handleClose = () => {
    setOpenProductModal(false);
    setModulesSelected([]);
    setTableData([]);
    setShowTimePeriod(false);
    setProductName("");
    setProductNameError("");
    setPackageName("");
    setProductSKU("");
    setSelectedCategories([]);
    setCategorySearchQuery("");
    setSelectedDays([]);
    setSelectedBrand(null);
    setSelectedOperator(null);
    setSelectedStatus({
      label: "Enable",
      value: "Enable",
    });
    setSelectedAgeRestriction({
      label: "Not Required",
      value: "0",
    });
    setSelectedSubCategory(null);
    setSalePrice("");
    setSalePriceError("");
    setProductCost("");
    setProductCostError("");
    setSellingStartTime("00:00");
    setSellingEndTime("00:00");
    setPurchaseLimit("");
    setPurchaseLimitError("");
    setShelfLife("");
    setWeight("");
    setWeightError("");
    setProductTax("0.0000");
    setProductTaxError("");
    setSubtitle("");
    setSubtitleError("");
    setDescription("");
    setNumberOfFiles(0);
    setImagesData(null);
    setAllImages([]);
    setBarcodeFile(null);
    setBarcodeFileName("");
    setProductPicture(null);
    setProductPictureName("");
    setMoreInfoFile(null);
    setMoreInfoFileName("");
    setIngredientsFile(null);
    setIngredientsFileName("");
    setInstructionsFile(null);
    setInstructionsFileName("");
    setRecommendProduct(false);
    setCheckExtension({
      product_picture: true,
      barcode: true,
      more_info: true,
      ingredients: true,
      instructions_video: true,
    });
  };

  const handleClick = (event) => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append("template", fileUploaded);

    setIsMutating(true);
    window.axiosIns
      .post(`/products/import`, formData)
      .then((resp) => {
        const {
          data: { data },
        } = resp;
        const errors = [];
        if (data.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`);
          });

          setImportError(errors);
          setIsInfo(true);
        }
        enqueueSnackbar(resp?.data?.message || "Products Added successfully.", {
          autoHideDuration: 3000,
        });
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add product. Try again."
          );
        }
        setIsMutating(false);
      })
      .then(() => {
        getDeviceType();
        setModulesSelected([]);
      });
  };

  const hiddenFileInput = React.useRef(null);

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        title="Products"
        description="
        All Products are listed here. You can create a new product that will be sold in a Vending Machine."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => {
              setModulesSelected([]);
              setShowTimePeriod(false);
              setOpenProductModal(true);
            }}
            disabled={current_user.type === "SU" || loader}
          />
          <EditButton
            disabled={
              modulesSelected.length === 0 ||
              current_user.type === "SU" ||
              loader
            }
            className="mr-3"
            label="Edit"
            onClick={async () => {
              if (modulesSelected.length > 1) {
                setCategoryEditModal(true);
              } else {
                const row = modulesSelected[0];
                if (row?.age_restriction == 0) {
                  setShowTimePeriod(false);
                } else {
                  setShowTimePeriod(true);
                }
                setSelectedAgeRestriction(
                  AGE_RESTRICTION_VALUES.find(
                    (age) => age.value === row?.age_restriction
                  )
                );
                setBarcodeFile(row?.barcode_of_product);
                const selectedBrand = brandList.find(
                  (brand) => brand.id === row?.brand
                );
                setSelectedBrand(
                  selectedBrand
                    ? {
                        label: selectedBrand.brand,
                        value: selectedBrand.id,
                      }
                    : null
                );
                setSelectedCategories(
                  categoryList.filter((category) =>
                    row?.categories.includes(category.id)
                  )
                );
                setProductCost(currencyFormatter(row?.cost));
                setNumberOfFiles(row?.images.length);
                setImagesData(row?.images);
                setAllImages([]);
                setProductPicture(row?.product_picture);
                setIngredientsFile(row?.ingredients_img);
                setMoreInfoFile(row?.more_info_img);
                setInstructionsFile(row?.instructions_video);
                setRecommendProduct(row?.is_recommended);
                setDescription(row?.long_description);
                const selectedDays =
                  row?.no_sale_days[0] === "[]"
                    ? []
                    : NO_SALE_DAYS.filter((day) =>
                        row?.no_sale_days.includes(day.value)
                      );
                setSelectedDays(selectedDays);
                const selectedOperator = customerList.find(
                  (operator) => operator.id === row?.operator
                );
                setSelectedOperator(
                  selectedOperator
                    ? {
                        label: `${selectedOperator.business_name} || (${selectedOperator.customer_type})`,
                        value: selectedOperator.id,
                      }
                    : null
                );
                setPackageName(row?.package_type);
                setSalePrice(currencyFormatter(row?.price));
                setSubtitle(row?.product_description);
                setProductName(row?.product_name);
                setProductSKU(row?.product_sku);
                setProductTax(taxFormatter(row?.product_tax));
                setPurchaseLimit(row?.purchase_limit);
                setSellingStartTime(row?.selling_start_time);
                setSellingEndTime(row?.selling_end_time);
                setShelfLife(row?.shelf_life);
                setSelectedStatus({ label: row?.status, value: row?.status });
                const selectedSubCategory = categoryList
                  .filter((data) => data.is_subcategory)
                  .find((x) => x.id === row?.sub_category);
                setSelectedSubCategory(
                  selectedSubCategory
                    ? {
                        label: selectedSubCategory.category_name,
                        value: selectedSubCategory.id,
                      }
                    : null
                );
                setWeight(row?.weight);
                setTableData(row?.language_variants);
                setOpenProductModal(true);
              }
            }}
          />
          <DeleteButton
            disabled={
              modulesSelected.length === 0 ||
              current_user.type === "SU" ||
              loader
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          {current_user.type !== "SU" && (
            <>
              <DeployButton
                disabled={loader}
                className="mr-3"
                label="Sync"
                onClick={() => syncPlanogram()}
              />
              <ExportButton
                disabled={loader}
                className="mr-3"
                label="Export"
                onClick={() => productExport()}
              />
              <ImportButton
                disabled={current_user.type === "SU" || loader}
                className="mr-3"
                label="Import"
                onClick={handleClick}
              />
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </>
          )}
        </div>
        <div className="d-flex">
          <SearchBox
            placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"product_sku"}
          searchColumnsFilter={true}
          fields={_flatten(fields)}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDeviceType(sortLabels[ordering] ?? ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getDeviceType(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
        <Dialog
          open={openProductModal}
          onClose={handleClose}
          maxWidth="lg"
          fullWidth={true}
          style={{ display: "block" }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Loader visible={crudLoader} />
          <DialogContent>
            <Grid container>
              <Grid item xs={6}>
                <DialogTitle style={{ padding: "0" }}>
                  {modulesSelected.length === 0
                    ? "Add Product"
                    : "Edit Product"}
                </DialogTitle>
                <DialogContentText>
                  {modulesSelected.length === 0
                    ? "Please fill in the details below."
                    : "Please edit the details below."}
                </DialogContentText>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                padding: "16px 0",
              }}
            >
              <Grid container spacing={2} xs={6}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    required={true}
                    label="Product Name"
                    value={productName}
                    error={!!productNameError}
                    helperText={productNameError || ""}
                    onChange={(e) => setProductName(e.target.value)}
                    onBlur={(e) => {
                      setProductName(e.target.value?.trim());
                      setProductNameError(validateProductName(e.target.value));
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    size="small"
                    options={filteredCategories}
                    getOptionLabel={(option) => option.category_name}
                    value={selectedCategories}
                    disableCloseOnSelect
                    limitTags={4}
                    onChange={(e, val) => setSelectedCategories(val)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Product Categories"
                        placeholder={
                          selectedCategories.length ? "" : "Search categories"
                        }
                        onChange={(e) => setCategorySearchQuery(e.target.value)}
                      />
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option.id}
                          label={option.category_name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    options={
                      brandList.length
                        ? [
                            ...brandList.map((x) => ({
                              label: x.brand,
                              value: x.id,
                            })),
                          ]
                        : [{ label: "", value: null }]
                    }
                    style={{ display: "block" }}
                    value={selectedBrand}
                    onChange={(_, val) => setSelectedBrand(val)}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "100%" }}
                        label="Product Brand"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                {getComponents(1)}
                <Grid item xs={6}>
                  <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    label="Package"
                    value={packageName}
                    onChange={(e) => setPackageName(e.target.value)}
                    onBlur={(e) => {
                      setPackageName(e.target.value?.trim());
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    label="Product SKU"
                    value={productSKU}
                    onChange={(e) => setProductSKU(e.target.value)}
                    onBlur={(e) => {
                      setProductSKU(e.target.value?.trim());
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    label="Sale Price ($)"
                    required={true}
                    value={salePrice}
                    error={!!salePriceError}
                    helperText={salePriceError || ""}
                    onChange={(e) => setSalePrice(e.target.value)}
                    onBlur={(e) => {
                      setSalePrice(e.target.value?.trim());
                      setSalePriceError(validateSalePrice(e.target.value));
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    label="Product Cost ($)"
                    required={true}
                    value={productCost}
                    error={!!productCostError}
                    helperText={productCostError || ""}
                    onChange={(e) => setProductCost(e.target.value)}
                    onBlur={(e) => {
                      setProductCost(e.target.value?.trim());
                      setProductCostError(validateProductCost(e.target.value));
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                {showTimePeriod &&
                  subCategory &&
                  subCategory["enable_product_selling_time"] && (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          type="time"
                          size="small"
                          label="Selling Start Time"
                          value={sellingStartTime ? sellingStartTime : "00:00"}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          onChange={(e) =>
                            handleTimeChange(
                              e.target.value,
                              setSellingStartTime
                            )
                          }
                          InputProps={{
                            endAdornment: sellingStartTime && (
                              <IconButton
                                onClick={() =>
                                  handleTimeChange("00:00", setSellingStartTime)
                                }
                                size="small"
                              >
                                <ClearIcon />
                              </IconButton>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type="time"
                          size="small"
                          label="Selling End Time"
                          value={sellingEndTime ? sellingEndTime : "00:00"}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          onChange={(e) =>
                            handleTimeChange(e.target.value, setSellingEndTime)
                          }
                          InputProps={{
                            endAdornment: sellingEndTime && (
                              <IconButton
                                onClick={() =>
                                  handleTimeChange("00:00", setSellingEndTime)
                                }
                                size="small"
                              >
                                <ClearIcon />
                              </IconButton>
                            ),
                          }}
                        />
                      </Grid>
                    </>
                  )}
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    label="Purchase Limit"
                    value={purchaseLimit}
                    error={!!purchaseLimitError}
                    helperText={purchaseLimitError || ""}
                    onChange={(e) => setPurchaseLimit(e.target.value)}
                    onBlur={(e) => {
                      setPurchaseLimit(e.target.value?.trim());
                      setPurchaseLimitError(
                        validatePurchaseLimit(e.target.value)
                      );
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    label="Shelf life (days)"
                    value={shelfLife}
                    onChange={(e) => setShelfLife(e.target.value)}
                    onBlur={(e) => {
                      setShelfLife(e.target.value?.trim());
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    label="Weight (in grams)"
                    value={weight}
                    error={!!weightError}
                    helperText={weightError || ""}
                    onChange={(e) => setWeight(e.target.value)}
                    onBlur={(e) => {
                      setWeight(e.target.value?.trim());
                      setWeightError(validateWeight(e.target.value));
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    options={[
                      { label: "Enable", value: "Enable" },
                      { label: "Disable", value: "Disable" },
                    ]}
                    style={{ display: "block" }}
                    value={selectedStatus}
                    onChange={(_, val) => setSelectedStatus(val)}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        style={{ width: "100%" }}
                        label="Status"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                {getComponents(2)}
                {subCategory && subCategory["enable_taxes_per_product"] && (
                  <Grid item xs={6}>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      label="Tax (%)"
                      value={productTax}
                      error={!!productTaxError}
                      helperText={productTaxError || ""}
                      onChange={(e) => setProductTax(e.target.value)}
                      onBlur={(e) => {
                        setProductTax(e.target.value?.trim());
                        setProductTaxError(validateTax(e.target.value));
                      }}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                )}
                {getComponents(3)}
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    size="small"
                    options={NO_SALE_DAYS}
                    getOptionLabel={(option) => option.label}
                    value={selectedDays}
                    disableCloseOnSelect
                    limitTags={4}
                    onChange={(e, val) => setSelectedDays(val)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="No Sale Days"
                        placeholder={selectedDays.length ? "" : "Select days"}
                      />
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option.value}
                          label={option.label}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </Grid>
              </Grid>
              <Grid container xs={6}>
                {productPicture ? (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      maxHeight: calculateImageMaxHeight(),
                      width: "100%",
                      padding: "8px 0",
                      marginLeft: "16px",
                      borderRadius: 5,
                      background: "#f3f3f3",
                      border: "1px solid #ccc",
                    }}
                  >
                    <IconButton
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        setProductPicture(null);
                        setProductPictureName("");
                      }}
                      style={{
                        width: "8px",
                        height: "8px",
                        position: "absolute",
                        top: -15,
                        right: -15,
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                      src={getImageSrc(productPicture)}
                      alt="Preview Image"
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                      marginLeft: "16px",
                      border: "5px dashed #e2e2e2",
                      borderRadius: 5,
                    }}
                  >
                    <div>
                      <FileUploadButton
                        size="small"
                        value={productPicture}
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setProductPictureName,
                            setProductPicture,
                            "product_picture"
                          )
                        }
                        tabIndex="19"
                        uploaded={productPictureName !== ""}
                        label="Product Picture"
                        style={{ whiteSpace: "nowrap" }}
                        className="mr-2"
                      />
                      <label
                        style={{
                          fontSize: 13,
                          display: "block",
                          color: "#858585",
                        }}
                      >
                        Suggested file types: png, jpg, jpeg, gif
                      </label>
                      {checkExtension?.product_picture ? (
                        <div
                          style={{
                            fontSize: 11,
                            maxWidth: 200,
                            overflowWrap: "break-word",
                            marginTop: 5,
                          }}
                        >
                          {productPictureName}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                padding: "8px 0px",
              }}
            >
              <Grid container spacing={2} xs={12}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    label="Subtitle"
                    value={subtitle}
                    error={!!subtitleError}
                    helperText={subtitleError || ""}
                    onChange={(e) => setSubtitle(e.target.value)}
                    onBlur={(e) => {
                      setSubtitle(e.target.value?.trim());
                      setSubtitleError(validateSubtitle(e.target.value));
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    label="Description"
                    multiline={true}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onBlur={(e) => {
                      setDescription(e.target.value?.trim());
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                xs={12}
                wrap="nowrap"
                style={{
                  padding: "16px 0px",
                }}
              >
                <Grid item xs={12 / 5}>
                  <div>
                    <FileUploadInfoButton
                      tabIndex="20"
                      uploaded={numberOfFiles !== "" && numberOfFiles !== 0}
                      size="small"
                      label="Additional Images"
                      style={{ whiteSpace: "nowrap" }}
                      open={() => setOpenAddlImagesModal(true)}
                      value={imagesData}
                      className="mr-2"
                    />
                    <label
                      style={{
                        fontSize: 13,
                        display: "block",
                        color: "#858585",
                      }}
                    >
                      Suggested file types: png, jpg, jpeg, gif
                    </label>
                    <div
                      style={{
                        fontSize: 11,
                        maxWidth: 200,
                        overflowWrap: "break-word",
                        marginTop: 5,
                      }}
                    >
                      {numberOfFiles
                        ? Number(numberOfFiles) > 1
                          ? `${numberOfFiles} Files uploaded successfully`
                          : `${numberOfFiles} File uploaded successfully`
                        : ""}
                    </div>
                  </div>
                  <ImagesModal
                    title="ADDITIONAL IMAGES"
                    data={imagesData || []}
                    imagesData={allImages}
                    open={openAddlImagesModal}
                    close={() => setOpenAddlImagesModal(false)}
                    // field={field}
                    onChange={() => {}}
                    fieldValues={{ images: imagesData }}
                    getImages={getImages}
                    removeImages={removeImages}
                    info={true}
                    changeIcon={false}
                  />
                </Grid>
                {subCategory && subCategory["enable_barcode"] && (
                  <Grid item xs={12 / 5}>
                    <FileUploadButton
                      size="small"
                      value={barcodeFile}
                      onChange={(e) =>
                        handleFileChange(
                          e,
                          setBarcodeFileName,
                          setBarcodeFile,
                          "barcode"
                        )
                      }
                      tabIndex="19"
                      uploaded={barcodeFileName !== ""}
                      label={
                        subCategory && subCategory["change_barcode_to_desc_img"]
                          ? "Description Image"
                          : "BarCode"
                      }
                      style={{ whiteSpace: "nowrap" }}
                      className="mr-2"
                    />
                    <label
                      style={{
                        fontSize: 13,
                        display: "block",
                        color: "#858585",
                      }}
                    >
                      Suggested file types: png, jpg, jpeg, gif
                    </label>
                    {checkExtension?.barcode ? (
                      <div
                        style={{
                          fontSize: 11,
                          maxWidth: 200,
                          overflowWrap: "break-word",
                          marginTop: 5,
                        }}
                      >
                        {barcodeFileName}
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                )}
                {subCategory && subCategory["enable_info_img"] && (
                  <Grid item xs={12 / 5}>
                    <FileUploadButton
                      size="small"
                      value={moreInfoFile}
                      onChange={(e) =>
                        handleFileChange(
                          e,
                          setMoreInfoFileName,
                          setMoreInfoFile,
                          "more_info"
                        )
                      }
                      tabIndex="20"
                      uploaded={moreInfoFileName !== ""}
                      label="More Info"
                      style={{ whiteSpace: "nowrap" }}
                      className="mr-2"
                    />
                    <label
                      style={{
                        fontSize: 13,
                        display: "block",
                        color: "#858585",
                      }}
                    >
                      Suggested file types: png, jpg, jpeg, gif
                    </label>
                    {checkExtension.more_info ? (
                      <div
                        style={{
                          fontSize: 11,
                          maxWidth: 200,
                          overflowWrap: "break-word",
                          marginTop: 5,
                        }}
                      >
                        {moreInfoFileName}
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                )}
                {subCategory && subCategory["enable_ingredient_img"] && (
                  <Grid item xs={12 / 5}>
                    <FileUploadButton
                      size="small"
                      value={ingredientsFile}
                      onChange={(e) =>
                        handleFileChange(
                          e,
                          setIngredientsFileName,
                          setIngredientsFile,
                          "ingredients"
                        )
                      }
                      tabIndex="20"
                      uploaded={ingredientsFileName !== ""}
                      label="Ingredients"
                      style={{ whiteSpace: "nowrap" }}
                      className="mr-2"
                    />
                    <label
                      style={{
                        fontSize: 13,
                        display: "block",
                        color: "#858585",
                      }}
                    >
                      Suggested file types: png, jpg, jpeg, gif
                    </label>
                    {checkExtension.ingredients ? (
                      <div
                        style={{
                          fontSize: 11,
                          maxWidth: 200,
                          overflowWrap: "break-word",
                          marginTop: 5,
                        }}
                      >
                        {ingredientsFileName}
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                )}
                {subCategory && subCategory["enable_video_description"] && (
                  <Grid item xs={12 / 5}>
                    <VideoUploadButton
                      size="small"
                      value={instructionsFile}
                      onChange={(e) =>
                        handleFileChange(
                          e,
                          setInstructionsFileName,
                          setInstructionsFile,
                          "instructions_video",
                          false
                        )
                      }
                      tabIndex="22"
                      uploaded={instructionsFileName !== ""}
                      label="Instructions Video"
                      style={{ whiteSpace: "nowrap" }}
                      className="mr-2"
                    />
                    <label
                      style={{
                        fontSize: 13,
                        display: "block",
                        color: "#858585",
                      }}
                    >
                      Suggested file types: .MOV,.MP4
                    </label>
                    {checkExtension.instructions_video ? (
                      <div
                        style={{
                          fontSize: 11,
                          maxWidth: 200,
                          overflowWrap: "break-word",
                          marginTop: 5,
                        }}
                      >
                        {instructionsFileName}
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                )}
              </Grid>
              {subCategory &&
                subCategory["enable_recommend_product"] === true && (
                  <Grid container spacing={2} xs={12}>
                    <Grid item xs={3}>
                      <CheckboxField
                        checked={recommendProduct}
                        onChange={(_, val) => {
                          setRecommendProduct(val);
                        }}
                        color="primary"
                        className="mr-2"
                      />
                      <Typography variant="body">
                        Recommended Product
                      </Typography>
                    </Grid>
                  </Grid>
                )}
            </Grid>
            <div
              style={{
                padding: "12px 0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setLanguageAddModal(true);
                  setTemporaryData();
                }}
                style={{
                  visibility: enable_product_dropdown ? "visible" : "hidden",
                }}
              >
                Add Language
              </Button>
              <div>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                  style={{ marginRight: "8px" }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                    modulesSelected.length === 0 ? handleAdd : handleEdit
                  }
                  disabled={isMutating}
                >
                  {modulesSelected.length === 0 ? "Add" : "Save"}
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <CrudDialog
          title="Bulk Edit"
          okText="Save"
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={editFields1}
          submitButtonDisabled={isMutating}
          values={modulesSelected[0]}
          onFieldChange={(rec, val) => {
            if (rec?.key === "age_restriction") {
              if (val == 0) {
                setShowTimePeriod(false);
              } else {
                setShowTimePeriod(true);
              }
            }
          }}
          onSubmit={(values) => {
            handleCategoryEdit(values);
          }}
          open={categoryEditModal}
          onClose={() => {
            setCategoryEditModal(false);
            setShowTimePeriod(false);
          }}
        />

        <CrudDialog
          title="Delete Product"
          description={
            modulesSelected.findIndex((x) => x.is_variant_exists === true) === 0
              ? "Deleting the product will also delete it's variants. Are you sure you want to delete?"
              : "Are you sure you want to delete the Product?"
          }
          okText="Delete"
          onSubmit={
            modulesSelected.findIndex((x) => x.is_variant_exists === true) === 0
              ? () => handleForceDelete()
              : () => handleDelete()
          }
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />

        <CrudDialog
          title="Delete Product Forcefully"
          description="Deleting the product will also delete it's variants. Are you sure you want to delete?"
          okText="Delete"
          onSubmit={() => handleForceDelete()}
          open={forcedeleteModal}
          onClose={() => {
            setForceDeleteModal(false);
            setDeleteModal(false);
          }}
        />
        <DialogWithTable
          open={languageAddModal}
          onClose={() => {
            setLanguageAddModal(false);
            setTemporaryData();
          }}
          onAdd={addEntry}
          onEdit={editEntry}
          onDelete={deleteEntry}
          tableData={tableData}
          languageList={
            languageList?.length
              ? [
                  ...languageList
                    .filter((x) => x.langauge != "English")
                    .map((x) => ({ label: x.langauge, value: x.id })),
                ]
              : [{ label: "", value: null }]
          }
          onDuplicate={updateExistingProducts}
          onCancelled={cancelEntry}
        />
        <InfoModal
          title="Product Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => setIsInfo(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(Product);
